import { Component, Input, OnInit } from '@angular/core';
import { text } from '@fortawesome/fontawesome-svg-core';
import { AuthenticatedGateway } from 'src/app/common/alex';

@Component({
  selector: 'app-harpy-search',
  templateUrl: './harpy-search.component.html',
  styleUrls: ['./harpy-search.component.css']
})
export class HarpySearchComponent implements OnInit {

  @Input()  authenticatedGateway: AuthenticatedGateway;

  clientsModelName = "customer"
  productModelName = "product"
  schemeModelName = "scheme"
  catalogModelName = "productCatalog"
  schemeBackendModelName = "schemeBackend"

  clientPlaceholderName = "CPF/CNPJ ou Nome"
  productPlaceholderName = "CPF/CNPJ ou ID do Produto"
  schemePlaceholderName = "Nome"
  catalogPlaceholderName = "Nome"
  schemeBackendPlaceholderName = "Nome"

  clientMultiIndexSearch = ["cpfCnpj", "customerName"]
  productMultiIndexSearch = ["owner", "connectionId"]
  schemeMultiIndexSearch = ["schemeName"]
  catalogMultiIndexSearch = ["name"]
  schemeBackendMultiIndexSearch = ["schemeBackendName"]

  constructor() {
  }

  ngOnInit(): void {
  }
}
