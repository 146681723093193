<div class="row">
    <div class="col-md-8">
        <div class="card">
            <div class="card-header">
              Cadastro do Cliente
            </div>
            <div class="card-body">
              <p class="card-text">Para iniciar, digite o CPF ou CNPJ do cliente.</p>
              <div class="row">
                <div class="mt-2 col-md-12">
                  <input type="text" mask="000.000.000-00||00.000.000/0000-00" (keyup)="cpfCnpjKeyUp()"
                   [(ngModel)]="clientForm.cpfCnpj"
                   placeholder="CPF/CNPJ" class="form-control">
                </div>
              </div>
              <div class="row">
                <div class="mt-2 col-md-12">
                  <input [(ngModel)]="clientForm.customerName" [attr.disabled]="formLock? true:null" 
                   type="text" placeholder="Nome" class="form-control">
                </div>
              </div>
              <div class="row">
                  <div class="mt-2 col-md-6">
                    <input [(ngModel)]="clientForm.customerContact.emailAddress"
                     [attr.disabled]="formLock? true:null" type="text" placeholder="Email" class="form-control">
                  </div>
                  <div class="mt-2 col-md-3">
                    <input [(ngModel)]="clientForm.customerContact.phone1"
                     [attr.disabled]="formLock? true:null" type="text" mask="(00)000000000||(00)00000000" placeholder="Telefone 1" class="form-control">
                  </div>
                  <div class="mt-2 col-md-3">
                    <input [(ngModel)]="clientForm.customerContact.phone2" 
                    [attr.disabled]="formLock? true:null" type="text" mask="(00)000000000||(00)00000000" placeholder="Telefone 2" class="form-control">
                  </div>
              </div>
              <div class="row">
                <div class="mt-2 col-md-2">
                  <input mask="00000-000" [(ngModel)]="clientForm.customerAddress.zip" (keyup)="zipKeyUp()"
                   [attr.disabled]="formLock? true:null" type="text" placeholder="CEP" class="form-control">
                </div>
                <div class="mt-2 col-md-2">
                  <input  [(ngModel)]="clientForm.customerAddress.city" 
                   [attr.disabled]="locationFormLock? true:null" type="text" placeholder="Cidade" class="form-control">
                </div>
                <div class="mt-2 col-md-2">
                  <input [(ngModel)]="clientForm.customerAddress.state" 
                   [attr.disabled]="locationFormLock? true:null" type="text" placeholder="Estado" class="form-control">
                </div>
                <div class="mt-2 col-md-6">
                  <input [(ngModel)]="clientForm.customerAddress.neighborhood" 
                   [attr.disabled]="locationFormLock? true:null" type="text" placeholder="Bairro" class="form-control">
                </div>
              </div>
              <div class="row">
                <div class="mt-2 col-md-8">
                  <input [(ngModel)]="clientForm.customerAddress.street" 
                   [attr.disabled]="locationFormLock? true:null" type="text" placeholder="Rua" class="form-control">
                </div>
                <div class="mt-2 col-md-2">
                  <input [(ngModel)]="clientForm.customerAddress.number" 
                   [attr.disabled]="locationFormLock? true:null" type="text" placeholder="Numero" class="form-control">
                </div>
                <div class="mt-2 col-md-2">
                  <input  [(ngModel)]="clientForm.customerAddress.complement" 
                   [attr.disabled]="locationFormLock? true:null" type="text" placeholder="Complemento" class="form-control">
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="col-md-12">
                  <button href="#" (click)="saveClient()" [attr.disabled]="formLock? true:null" class="btn btn-safe float-right">Salvar</button>
                </div>
              </div>
            </div>
          </div>
    </div>

    <div class="col-md-4">
        <div class="card">
            <div class="card-header">
              Produtos
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <button href="#" [attr.disabled]="formLock? true:null" class="btn btn-safe float-right" (click)="newProduct()">Novo Produto</button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">

                  <div class="card mt-2"  *ngFor="let product of products; let i = index ">
                    <div class="card-header">
                      <fa-icon [icon]="conn" class="mr-2" aria-hidden="true"></fa-icon>{{product.productInnerSchemeId}} - {{product.productName}}
                      <button href="#" class="btn btn-warning float-right" (click)="editProduct(i)">Edit</button>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-6">
                          <fa-icon [icon]="building" class="mr-2" aria-hidden="true"></fa-icon> {{product.scheme.schemeName}}
                        </div>
                        <div class="col-md-6">
                          <fa-icon [icon]="speed" class="mr-2" aria-hidden="true"></fa-icon> {{product.fromCatalog.rateLimit}} Mbps
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <fa-icon [icon]="moneyCheck" class="mr-2" aria-hidden="true"></fa-icon>R$ {{product.frontProductAssignedPrice}}
                        </div>
                        <div class="col-md-6">
                          <fa-icon [icon]="calendar" class="mr-2" aria-hidden="true"></fa-icon>{{product.productPaymentDue}}
                        </div>
                      </div>
                      <hr>
                      <div class="row">
                        <div class="col-md-6">
                          <fa-icon [icon]="conn" class="mr-2" aria-hidden="true"></fa-icon>{{product.connectionId}}
                        </div>
                        <div class="col-md-6">
                          <fa-icon [icon]="lock" class="mr-2" aria-hidden="true"></fa-icon>{{product.password}}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
    </div>

</div>

<!-- Product -->
<ng-template #productModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Produto</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body p-5">
      <div class="row mt-2">
        <div class="col-md-12">
          <input type="text" placeholder="Scheme" (keyup)="schemeOnKeyUp()" 
            (change)="onchangeScheme($event.target.value)"
            [(ngModel)]="productOnModal.scheme.schemeName"
            list="autoComplSchemaHarpProd" autocomplete="off"
            class="form-control">
          <datalist id="autoComplSchemaHarpProd">
            <option *ngFor="let option of schemesApiAutocompleate.result" [value]="option.schemeName">
                {{option._id}}
            </option>
          </datalist>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <input type="text" placeholder="Produto do Catálogo" (keyup)="productCatalogOnKeyUp()"
            list="autoCompl2" autocomplete="off"
            [(ngModel)]="productOnModal.fromCatalog.name"
            (change)="onchangeProductCatalog($event.target.value)"
            class="form-control">
        </div>
        <datalist id="autoCompl2">
          <option *ngFor="let option of productCatalogApiAutocompleate.result"
           [value]="option.name">
              {{option._id}}
          </option>
        </datalist>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <input type="text" 
          [(ngModel)]="productOnModal.productName"
          placeholder="Nome do Produto" class="form-control">
        </div>
      </div>
      
      <div class="row mt-2">
        <div class="col-md-12">
          <input currencyMask 
            [(ngModel)]="productOnModal.productAssignedPrice"
            placeholder="Valor do plano" class="form-control"
            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:'left' }"
          />
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <input type="number" step="1" 
          [(ngModel)]="productOnModal.productPaymentDue"
          placeholder="Dia do Vencimento" class="form-control">
        </div>
      </div>

      <label class="mt-2">Data de inserção no SPIX:</label>

      <div class="row mt-2">
        <div class="col-md-12">
          <input type="date"
          [(ngModel)]="productOnModal.spixInsertionDate"
          placeholder="Dia de inserção no SPIX" class="form-control">
        </div>
      </div>

      <hr>
      <div class="row mt-2">
        <div class="col-md-12">
            <input class="btn btn-secondary float-right xbtn" (click)="generateUsrPwd()" type="button" value="Gerar Usuário e Senha">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <input type="text" 
          [(ngModel)]="productOnModal.productInnerSchemeId"
          placeholder="ID Interno do Produto" class="form-control">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <input type="text"
          [(ngModel)]="productOnModal.connectionId"
          placeholder="ID da conexão" class="form-control">
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <input type="text" 
          [(ngModel)]="productOnModal.password"
          placeholder="Senha da conexão" class="form-control">
        </div>
      </div>
      <hr>
      <div class="row mt-3" *ngIf="!productIsBeingEdit">
          <div class="col-md-12">
              <input class="btn btn-secondary float-right xbtn" (click)="translateToIdAndSaveProduct()" type="button" value="Salvar">
          </div>
      </div>
      <div class="row mt-3" *ngIf="productIsBeingEdit">
        <div class="col-md-3">
          <input class="btn btn-danger float-left xbtn" (click)="deleteProduct()" type="button" value="Excluir Produto">
        </div>
        <div class="col-md-3">
          <input class="btn btn-primary float-right xbtn" (click)="changeProductOwner()" type="button" value="Transferir Titularidade">
        </div>
        <div class="col-md-6">
            <input class="btn btn-safe float-right xbtn" (click)="translateToIdAndSaveProduct()" type="button" value="Salvar">
        </div>
    </div>
  </div>
</ng-template>


<ng-template #ownerChangeModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Transferência de Titularidade</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body p-5">
      <div class="row mt-2">
        <div class="col-md-12">
          <input type="text" placeholder="CPF/CNPJ novo Titular" mask="000.000.000-00||00.000.000/0000-00" (keyup)="ownerCpfCnpjKeyUp()" 
            (change)="onchangeCpfCnpjTransfer($event.target.value)"
            [(ngModel)]="ownerChangeForm.cpfCnpjNewOwner"
            class="form-control">
        </div>
      </div>
      <div class="" *ngIf='ownerChangeForm.newOwnerObj != undefined'>
        <hr>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ownerChangeForm.newOwnerObj.customerName}}</h5>
            <h6 class="card-subtitle mb-2 text-muted"><i>{{ownerChangeForm.newOwnerObj.cpfCnpj}}</i></h6>
            <p class="card-text">
              {{ownerChangeForm.newOwnerObj.customerAddress.street}}, {{ownerChangeForm.newOwnerObj.customerAddress.number}} - {{ownerChangeForm.newOwnerObj.customerAddress.complement}}
              <br>
              {{ownerChangeForm.newOwnerObj.customerAddress.neighborhood}} - {{ownerChangeForm.newOwnerObj.customerAddress.city}} - {{ownerChangeForm.newOwnerObj.customerAddress.state}}
            </p>
          </div>
        </div>
      </div>
      <hr>
      <div class="row mt-3" *ngIf="productIsBeingEdit">
        <div class="col-md-12">
            <input class="btn btn-safe float-right xbtn" (click)="makeTransfer()" type="button" value="Salvar">
        </div>
    </div>
  </div>
</ng-template>

