import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { ApiAutoCompleateModel } from '../harpy'
import Swal from 'sweetalert2';
import { AuthenticatedGateway } from 'src/app/common/alex';
import { icon } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-harpy-schemes',
  templateUrl: './harpy-schemes.component.html',
  styleUrls: ['./harpy-schemes.component.css']
})
export class HarpySchemesComponent implements OnInit {
  @Input()  authenticatedGateway: AuthenticatedGateway;
  harpyLoading = false
  backendAutoCompleate = undefined
  
  httpOptions = {
    headers: new HttpHeaders({ 
      'Access-Control-Allow-Origin':'*',
    })
  };

  schemeForm = {
    schemeName: '', 
    schemeContact:{
        phone1: '',
        phone2: '',
        emailAddress: '',
    },
    schemeAddress:{
        zip:'',
        city:'',
        state:'',
        street:'',
        number:'',
        neighborhood:'',
        complement:'',
    },
    backend: {schemeBackendName:''},
    schemeCapacity: '',
    implementationDate: '',
    schemeIp:'',
    schemePassword:'',
  }
  formLock = true
  locationFormLock = true
  schemeIsBeingEdit = false

  searchScheme(){
    this.harpyLoading = true
    this.authenticatedGateway.authPost(
      'harpy/scheme/query', 
      {schemeName:this.schemeForm.schemeName}
    ).then(
      (api_resp) => {
        let d = api_resp.payload
        if(d.details.length > 0){
          this.schemeForm = d.details[0]
          this.formLock = false
          this.locationFormLock = false
          this.schemeIsBeingEdit = true
        }
        else{
          Swal.fire({
            title:"Scheme não cadastrado ainda", 
            text:' Formulário desbloqueado', timer: 3500,
            showConfirmButton: false,
            toast: true,
            position: 'top-right'
          })
          this.schemeIsBeingEdit = false
          this.formLock = false
        }
      }
    ).catch((e)=>{
      console.info(e)
    })
  }

  getZip(){
    this.authenticatedGateway.authGet(
      'harpy/getZipInfo/'+this.schemeForm.schemeAddress.zip
    ).then(
      (api_resp) => {
        let d = api_resp.payload;
        if(d.status == true){
          if(d.contents.erro == undefined){
            this.schemeForm.schemeAddress = {
              zip:this.schemeForm.schemeAddress.zip,
              city:d.contents.localidade,
              state:d.contents.uf,
              street:d.contents.logradouro,
              number:this.schemeForm.schemeAddress.number,
              neighborhood:d.contents.bairro,
              complement:this.schemeForm.schemeAddress.complement,
            }
            this.locationFormLock = false
          }
          else{        
            this.locationFormLock = true
            Swal.fire({
              title:"CEP Inválido", 
              text:'verifique e tente novamente', timer: 3500,
              showConfirmButton: false,
              toast: true,
              position: 'top-right'
            })
          }
      }
    }).catch(
      (e)=>{
        console.info(e)
      }
    )
  }

  zipKeyUp(){
    if(this.schemeForm.schemeAddress.zip.length == 8){
      this.getZip()
    }
    else{
      this.locationFormLock = true
    }
  }
  saveScheme(){
    if(this.schemeIsBeingEdit){
      var toSave = Object.assign({}, this.schemeForm);
      delete(toSave['_id'])
      delete(toSave['__v'])
      
      Swal.fire({
        title: 'Salvando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading()

          return this.authenticatedGateway.authPatch(
            'harpy/scheme/item/'+this.schemeForm['_id'],
            toSave
          ).then(
            (api_resp) => {
              let d = api_resp.payload
              Swal.close()
            if(d.status){
              this.searchScheme()
              Swal.fire("Pronto", 'Tudo certo!', 'success').then(()=>{
              })
            } 
            else{
              Swal.fire("Algo deu errado", d.details, 'error')
            }
            }
          ).catch(
            (e) => {
              console.info(e)
              Swal.insertQueueStep({icon: 'error', title:"Algo deu errado"})
            }
          )
        }
      })
    }
    else{
      var toSave = Object.assign({}, this.schemeForm);
      Swal.fire({
        title: 'Salvando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading()
          return this.authenticatedGateway.authPost(
            'harpy/scheme', toSave
          ).then(
            (api_resp) => {
              let d = api_resp.payload
              Swal.close()
              if(d.status){
                this.searchScheme()
                Swal.fire("Pronto", 'Tudo certo!', 'success').then(()=>{})
              } 
              else{
                Swal.fire("Algo deu errado", d.details, 'error')
              }
            }
          ).catch(
            (e) => {
              console.info(e)
              Swal.insertQueueStep({
                icon: 'error',
                title: 'Algo deu errado!'
              })
            }
          )
        }
      })
    }
  }

  backendOnKeyUp(){
    this.backendAutoCompleate.searchTerm = this.schemeForm.backend.schemeBackendName
    this.backendAutoCompleate.apiAutoCompleate()
  }

  clearForm(){
    this.schemeForm = {
      schemeName: this.schemeForm.schemeName, 
      schemeContact:{
          phone1: '',
          phone2: '',
          emailAddress: '',
      },
      schemeAddress:{
          zip:'',
          city:'',
          state:'',
          street:'',
          number:'',
          neighborhood:'',
          complement:'',
      },
      backend: {schemeBackendName:''},
      schemeCapacity: '',
      implementationDate: '',    
      schemeIp:'',
      schemePassword:'',
    }
    this.formLock = true
    this.locationFormLock = true
  }

  generatePassword(length) {
    var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  generateSchemePassword(){
    var pass = this.generatePassword(32)
    this.schemeForm.schemePassword = pass
  }


  constructor(private http:HttpClient) {
    
  }

  ngOnInit(): void {
    this.clearForm()
    this.backendAutoCompleate = new ApiAutoCompleateModel(this.authenticatedGateway, 'schemeBackendName', 'schemeBackend')
  }

}
