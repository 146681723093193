import { HttpClient } from '@angular/common/http';
import { Modifier, spixCalls } from '../../models/models'
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { ThrowStmt } from '@angular/compiler';
import { Subject } from 'rxjs';

import { AuthenticatedGateway } from '../../../common/alex';


export class spixConnector{
    spix: spixCalls = undefined
    token:string = undefined
    triggerReset: Subject<boolean> = new Subject<boolean>();

    subscriptions: Array<any> = []
    selectedPayment = ""
    selectModifier = ""
    payments: Array<any> = []
    details: Array<any> = []

    modifiers: object = {'active':[], 'expired':[], 'canceled':[]}
    newPaymentForm = {
        expire_date:'',
        payment_amount:'',
        payment_text:''
    }
    clearNewPayment(){
        this.newPaymentForm = {
            expire_date:'',
            payment_amount:'',
            payment_text:''
        }
    }
    
    newModifiers: Array<Modifier> = []
    singleSearchForm = {
        searchText:'',
        searchOption: 'none',     
    }
    multipleSearchForm = {
        searchText:[],
        searchOption: 'none',     
    }

    constructor(private authenticatedGateway: AuthenticatedGateway){
        this.spix = new spixCalls(authenticatedGateway)
    }
    async getNumbers():Promise<object>{
        return this.spix.numbers()
    }

    clearCpfCnpj(cpfCnpj:string):string {
        cpfCnpj = cpfCnpj.split('.').join('')
        cpfCnpj = cpfCnpj.split('/').join('')
        cpfCnpj = cpfCnpj.split('-').join('')
        return cpfCnpj
    }

    async singleSearch():Promise<object> {
        this.triggerReset.next(true)
        this.subscriptions = []
        this.modifiers = []
        if(this.singleSearchForm.searchOption == 'none')
            return {status:false, details:"Você deve escolher uma opção de pesquisa."}
        if(this.singleSearchForm.searchText == '')
            return {status:false, details:"O campo de pesquisa não pode estar em branco."}

        var query = undefined

        if(this.singleSearchForm.searchOption == 'cpfCnpj'){
            this.singleSearchForm.searchText = this.clearCpfCnpj(this.singleSearchForm.searchText)
            var query = await this.spix.getCpfCnpjSubs(this.singleSearchForm.searchText)
            this.subscriptions = query.active
            this.subscriptions = this.subscriptions.concat(query.defaulted)
            this.subscriptions = this.subscriptions.concat(query.blocked)
            this.subscriptions = this.subscriptions.concat(query.inactive)
        }
        
        else if(this.singleSearchForm.searchOption == 'idProduto'){
            var query = await this.spix.getProductIdSubs(this.singleSearchForm.searchText)
            this.subscriptions = query.content
        }
        if(query == undefined || 
            (Object.keys(query).length === 0 && query.constructor === Object) || 
            (this.subscriptions.length == 0)
        ){
            this.triggerReset.next(false)
            return {status:false, details:"A pesquisa não obteve resultados, verifique as chaves e tente mais uma vez."}
        }
        else{
            this.triggerReset.next(false)
            return {status:true, value:query}
        }
    }

    async multipleSearch():Promise<object> {
        this.triggerReset.next(true)
        this.subscriptions = []
        this.modifiers = []
        if(this.multipleSearchForm.searchOption == 'none')
            return {status:false, details:"Você deve escolher uma opção de pesquisa."}
        if(this.multipleSearchForm.searchText.length == 0)
            return {status:false, details:"O campo de pesquisa não pode estar em branco."}

        var query = undefined

        if(this.multipleSearchForm.searchOption == 'cpfCnpj'){
            query = await this.spix.getMultipleCpfCnpj(this.multipleSearchForm.searchText)
            this.multipleSearchForm.searchText.forEach(e =>{
                this.subscriptions = this.subscriptions.concat(query.active[e])
                this.subscriptions = this.subscriptions.concat(query.defaulted[e])
                this.subscriptions = this.subscriptions.concat(query.inactive[e])
            })
        }
        
        else if(this.multipleSearchForm.searchOption == 'idProduto'){
            query = []
            for(var x = 0; x < this.multipleSearchForm.searchText.length; x++){
                let temp = await this.spix.getProductIdSubs(this.multipleSearchForm.searchText[x])
                query.push(temp.content)
                this.subscriptions = this.subscriptions.concat(temp.content)
            }
        }
        if(query == undefined || 
            (Object.keys(query).length === 0 && query.constructor === Object) || 
            (this.subscriptions.length == 0)
        ){
            this.triggerReset.next(false)
            return {status:false, details:"A pesquisa não obteve resultados, verifique as chaves e tente mais uma vez."}
        }
        else{
            this.triggerReset.next(false)
            return {status:true, value:query}
        }

    }

    async bringPaymentsForSubscription(id:string):Promise<object>{
        this.selectedPayment = id
        var e = await this.spix.getPaymentsForSubscription(id)
        this.payments = e.open
        this.payments = this.payments.concat(e.paid)
        this.payments = this.payments.concat(e.defaulted)
        this.payments = this.payments.concat(e.cancelled)
        return {status:true}
    }
    async bringPaymentDetails(id:string):Promise<object>{
        return this.spix.getPaymentDetails(id)
    }
    async cancelPayment(id:string):Promise<object>{     
        return await this.spix.cancelPayment(id)
    }
    async modifierGet(id:string):Promise<object>{
        this.selectModifier = id
        var value = 0
        for(var x = 0; x < this.subscriptions.length; x++){
            if(this.subscriptions[x].id == id){
                value = this.subscriptions[x].plan_base_amount;
            }
        }
        this.modifiers = await this.spix.getModifiersForSubscription(id, value)
        return {status:true}
    }

    async createPayment():Promise<object>{
        this.newPaymentForm['fromSubscriptionId'] = this.selectedPayment
        if(this.newPaymentForm.payment_amount.length < 4){
            Swal.fire({
                title:"Opps", 
                text:'Seu valor está no formato errado, verifique..', timer: 5000,
                showConfirmButton: false,
                toast: true,
                icon:'error',
                position: 'top-right'
            })
            return
        }
        return await this.spix.createNewPayment(this.newPaymentForm)
    }

    async emitPayment(id):Promise<object>{
        return await this.spix.emitNewPayment(id)
    }
    async markAsPaid(idx){
        return await this.spix.markAsPaid(idx)
    }

    editModifier(modifier:Modifier):void{
        this.spix.saveModifier(modifier).then(e => {
            if(e['status']){
                Swal.fire({
                    title:"Sucesso", 
                    text:'Seu modificador foi alterado.', timer: 5000,
                    showConfirmButton: false,
                    toast: true,
                    icon:'success',
                    position: 'top-right'
                })
                this.modifierGet(this.selectModifier)
            }
        })
    }
    cancelModifier(modifier:Modifier):void{
        Swal.fire({
            title: 'Você tem certeza que deseja cancelar esse modificador?',
            text: "Essa ação não pode ser revertida!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, eu tenho certeza!',
            cancelButtonText: 'Não'
            
          }).then((result) => {
            if (result.isConfirmed) {
                this.spix.cancelModifier(modifier).then(e => {
                    if(e['status']){
                        Swal.fire({
                            title:"Sucesso", 
                            text:'Seu modificador foi cancelado.', timer: 5000,
                            showConfirmButton: false,
                            toast: true,
                            icon:'success',
                            position: 'top-right'
                        })
                        this.modifierGet(this.selectModifier)
                    }
                    
                })
            }
        })
    }
    newModifier(){
        var value = 0
        var today = new Date()
        for(var x = 0; x < this.subscriptions.length; x++){
            if(this.subscriptions[x].id == this.selectModifier){
                value = this.subscriptions[x].plan_base_amount;
            }
        }
        
        this.newModifiers.push(new Modifier('', value, 0, today.toISOString(), '', null, null))
    }
    removeNewModifier(idx){
        var filtered = this.newModifiers.filter(function(value, index, arr){ return index != idx;});
        this.newModifiers = filtered
    }
    addModifier(idx){
        Swal.fire({
            title: 'Você tem certeza que deseja salvar?',
            text: "Você poderá reverter essa ação, mas ficará salvo no sistema!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, eu tenho certeza!'
          }).then((result) => {
            if (result.isConfirmed) {
                this.spix.createModifier(this.newModifiers[idx], this.selectModifier).then(e=>{
                    if(e['status'] == true){
                        this.removeNewModifier(idx)
                        this.modifierGet(this.selectModifier)
                        Swal.fire({
                            title:"Sucesso", 
                            text:'Seu modificador foi criado.', timer: 5000,
                            showConfirmButton: false,
                            toast: true,
                            icon:'success',
                            position: 'top-right'
                        })
                    }
                })
            }
          })
    }
    reactivateSubs(idx){
        Swal.fire({
            title: 'Você tem certeza que deseja reativar essa assinatura',
            text: "Será criada uma nova assinatura, você terá que nos informar algumas coisas.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, eu tenho certeza!',
            cancelButtonText: 'Não'
          }).then((result) => {
              if(result.isConfirmed)
                Swal.fire({
                    title:'Manter o mesmo titular?',
                    showCancelButton: true,
                    confirmButtonText: 'Sim',
                    cancelButtonText: 'Não'
                }).then((result)=>{
                    if(result.isConfirmed){}
                    else{
                        
                    }
                })
          })
    }

    async renegotiateDebt(idx){
        return await this.spix.getRenegotiateForm(idx)
    }
    async emitRenegotiation(idx, form){
        return await this.spix.emitRenegotiation(idx, form)
    }
    
}