<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item">
    <a class="nav-link active" id="pesquisaUnica-tab" data-toggle="tab" href="#pesquisaUnica" role="tab" aria-selected="true">Pesquisa Unica</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="pesquisaMassiva-tab" data-toggle="tab" href="#pesquisaMassiva" role="tab"aria-selected="false">Pesquisa Massiva</a>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="pesquisaUnica" role="tabpanel">
    <div class="row mt-4 mb-4">
      <div class="col-md-9">
        <input [(ngModel)]="connector.singleSearchForm.searchText" class="form-control" type="search" name="" id="">
      </div>
      <div class="col-md-2 text-center mt-2">
        <div class="form-check form-check-inline">
          <input [(ngModel)]="connector.singleSearchForm.searchOption" name="1" class="form-check-input" type="radio"
            value="cpfCnpj" checked>
          <label class="form-check-label">
            CPF ou CNPJ
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input [(ngModel)]="connector.singleSearchForm.searchOption" name="2" class="form-check-input" type="radio"
            value="idProduto">
          <label class="form-check-label">
            ID Produto
          </label>
        </div>
      </div>
      <div class="col-md-1">
        <input class="btn btn-primary" (click)="doUniqueSearch()" type="button" value="Procurar">
      </div>
    </div>
  </div>
  <div class="tab-pane fade" id="pesquisaMassiva" role="tabpanel">
    <div class="tab-pane fade show active" id="pesquisaUnica" role="tabpanel">
      <div class="row mt-4 mb-4">
        <div class="col-md-9">
          <input [(ngModel)]="multiInputTemp"  
          (keyup.enter)="enterMultiSearch()" 
          (paste)="onPaste($event)"
          class="form-control" type="search" name="" id="">
        </div>
        <div class="col-md-2 text-center mt-2">
          <div class="form-check form-check-inline">
            <input [(ngModel)]="connector.multipleSearchForm.searchOption" class="form-check-input" type="radio"
              value="cpfCnpj" name="3" checked>
            <label class="form-check-label">
              CPF ou CNPJ
            </label>
          </div>
          <div class="form-check form-check-inline">
            <input [(ngModel)]="connector.multipleSearchForm.searchOption" class="form-check-input" type="radio"
              id="multi-search-product-option" name="3" value="idProduto">
            <label class="form-check-label">
              ID Produto
            </label>
          </div>
        </div>
        <div class="col-md-1">
          <input class="btn btn-primary" (click)="doMultipleSearch()" type="button" value="Procurar">
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 d-flex flex-row">
          <div class="boxy m-1" *ngFor="let search_term of connector.multipleSearchForm.searchText" (click)="clickOnSearchTerm(search_term)">
            {{ search_term }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<hr>
<app-spix-view [(authenticatedGateway)]="authenticatedGateway" [(connector)]="connector"></app-spix-view>

<br>
