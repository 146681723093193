<!-- View -->
<div class="row" style="min-height:700px;">
    <div class="col-md-8" id="show_subscriptions" [ngClass]="subscriptionsFullOn ? 'col-md-12' : 'col-md-8'">
        <div class="card">
            <div class="card-header">
                <h5 class="card-title">
                    Assinaturas
                    <input *ngIf="hasCloseControl" class="btn btn-danger-dark float-right xbtn" (click)="toggleCloseControl()" type="button" value="x">
                </h5>
            </div>
            <div class="card-body">
                <div class="card-text text-center" style="max-height: 700px; overflow-y: scroll; overflow-x: hidden;">
                    <div class="row mt-3" *ngFor="let subscription of connector.subscriptions; let i = index">
                        <div class="col-md-12">
                            <div class="card ">
                                <div class="card-header bg-fiberfast-lighter text-left">
                                    <h5 class="card-title"># {{ subscription.id }} - {{ subscription.plan_id }} - {{ subscription.plan_name }}</h5>
                                </div>
                                <div class="card-body text-left">
                                    <h6 class="card-subtitle text-muted">
                                        <div class="row">
                                            <div class="col-md-4"><p><b>Criado em:</b> {{ subscription.createdAt | date:'dd/LL/yyyy' : 'GMT'  }}</p></div>
                                            <div class="col-md-4">
                                                <p><b>Documento Titular:</b> {{ subscription.owner }}</p>
                                                <br>
                                                <p><b>Nome Titular: </b> {{ subscription.name }}</p>
                                            </div>
                                            <div class="col-md-4" *ngIf="subscription.status == 0"><p>
                                                <b>Status: </b> <b class="title-safe">Ativo</b>
                                            </p></div>
                                            <div class="col-md-4" *ngIf="subscription.status == 1"><p>
                                                <b>Status: </b> <b class="title-warn">Inadimplente</b>
                                            </p></div>
                                            <div class="col-md-4" *ngIf="subscription.status == 3"><p>
                                                <b>Status: </b> <b class="title-danger">Bloqueado</b>
                                            </p></div>
                                            <div class="col-md-4" *ngIf="subscription.status == 2"><p>
                                                <b>Status: </b> <b class="title-danger">Inativo</b>
                                                <br>
                                                <b>Data de Efetivação: </b> {{ subscription.cancelation.effective_from | date:'dd/LL/yyyy' : 'GMT'  }}
                                                <br>
                                                <b>Motivo do cancelamento: </b> {{ subscription.cancelation.cancelation_text }}
                                            </p></div>
                                        </div> 
                                    </h6>
                                    <hr>
                                    <div class="row">
                                        <div class="col-md-2"><p><b>Dia do Vencimento:</b></p></div>
                                        <div class="col-md-2"> <p>{{subscription.signature_day_of_month}}</p></div>
                                        <div class="col-md-2"><p><b>Valor do Plano (R$):</b></p></div>
                                        <div class="col-md-2"> <p>{{subscription.plan_base_amount | currency:'BRL':true:'1.2-2'}} </p></div>
                                        <div class="col-md-4"><p><b>Fidelidade:</b> {{ subscription.signature_loyalty_until_date | date:'dd/LL/yyyy' : 'GMT'  }}</p></div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-md-2"><a class="btn btn-primary" (click)="paymentsSubscription(subscription.id)" type="button">Ver Cobranças</a></div>
                                        <div class="col-md-4"><a class="btn btn-secondary" (click)="modifierGet(subscription.id, subscription.plan_base_amount)" type="button">Ver Modificadores</a></div>
                                        <div class="col-md-2" *ngIf="subscription.status == 1"><a class="btn btn-safe" (click)="renegotiate(subscription.id)" type="button">Renegociar Dívida</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="col-md-4"  [ngClass]="subscriptionsFullOn ? 'hide' : 'show'" >
        <div class="row">
            <div class="col-md-12">
                <div class="card" style="height: 100%;">
                    <div class="card-header">
                        <h5 class="card-title">Pagamentos - #{{ connector.selectedPayment }} <input class="btn btn-danger-dark float-right xbtn" (click)="subscriptionFullOnToggle()" type="button" value="x"></h5>
                    </div>
                    <div class="card-body" style="overflow-y: scroll;">
                        <div class="card-text text-center">
                            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover" [attr.id]="randomTableId">
                                <thead>
                                  <tr>
                                    <th>id</th>
                                    <th>Vencimento</th>
                                    <th>Valor</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let payment of connector.payments">
                                    <td>{{ payment.id }}</td>
                                    <td>{{ payment.expire_date | date:'dd/LL/yyyy' : 'GMT' }}</td>
                                    <td>{{ payment.payment_amount | currency:'BRL':true:'1.2-2' }}</td>
                                    <td>
                                        <b *ngIf="payment.status==0"class="title-warn">Criado</b>
                                        <b *ngIf="payment.status==1"class="title-warn">Emitido</b>
                                        <b *ngIf="payment.status==2"class="title-safe">Pago</b>
                                        <b *ngIf="payment.status==3"class="title-danger">Inadimplente</b>
                                        <b *ngIf="payment.status==4"class="title-danger">Cancelado</b>
                                    </td>
                                  </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pt-2" [ngClass]="paymentDetails ? 'show' : 'hide'">
            <div class="col-md-12 full"> 
                <div class="card" style="height: 100%;" >
                    
                    <div *ngIf="!detailsIsLoaded" class="card-body" style="margin:auto; height: 100%;">
                        <div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>

                    <div class="card-header" *ngIf="detailsIsLoaded">
                        <h5 class="card-title">
                            # {{ details.payment.id }} - {{ details.payment.payment_amount | currency:'BRL':true:'1.2-2' }}    
                        <input class="btn btn-danger-dark float-right xbtn" (click)="paymentDetailsToggle()" type="button" value="x"></h5>    
                    </div>
                    <div class="card-body" style="overflow-y: scroll;" *ngIf="detailsIsLoaded">
                            <h6 class="card-subtitle text-muted">
                                <div class="row">
                                    <div class="col-md-4"><p><b>Vencimento:</b> {{ details.payment.expire_date | date:'dd/LL/yyyy' : 'GMT'  }}</p></div>
                                    <div class="col-md-4" *ngIf="details.payment.payment_sent"><p>
                                        <b>Pagamento Enviado: </b> <b class="title-safe">Sim</b>
                                    </p></div>
                                    <div class="col-md-4" *ngIf="details.payment.payment_sent == false"><p>
                                        <b>Pagamento Enviado: </b> <b class="title-danger">Não</b>
                                    </p></div>
                                    <div class="col-md-4">
                                        <b *ngIf="details.payment.status == 0" class="title-warn">Criado</b>
                                        <b *ngIf="details.payment.status == 1" class="title-warn">Emitido</b>
                                        <b *ngIf="details.payment.status == 2" class="title-safe">Pago</b>
                                        <b *ngIf="details.payment.status == 3" class="title-danger">Inadimplente</b>
                                        <b *ngIf="details.payment.status == 4" class="title-danger">Cancelado</b>
                                </div>
                                </div> 
                            </h6>
                            <hr>
                            <div class="row">
                                
                                <div class="col-md-12">
                                    <p>Texto do Pagamento:</p>
                                    <p *ngFor="let _s of details.payment.payment_text">{{ _s }}</p>
                                </div>
                            </div>

                            <div>
                                <hr>
                                <div class="row" *ngIf="details.payment.status != 0 && details.payment.status != 2">
                                    <div class="col-md-12">
                                        <b>Código de Barras: </b>
                                    </div>
                                </div>
                                <div class="row" *ngIf="details.payment.status != 0 && details.payment.status != 2">
                                    <div class="col-md-12">
                                        {{ details.bankData.payment.banking_billet.barcode }}
                                    </div>
                                </div>
                                <div class="row mt-2" *ngIf="details.payment.status != 0 && details.payment.status != 2">
                                    <div class="col-md-6"><a class="btn btn-primary float-left" href="{{ details.bankData.payment.banking_billet.link }}" target="_blank" type="button">Ver Cobrança</a></div>
                                    <div class="col-md-6"><a class="btn btn-danger float-right" href="{{ details.bankData.payment.banking_billet.pdf.charge }}" target="_blank" type="button">Download PDF</a></div>
                                </div>
                                <div class="row mt-2" *ngIf="details.payment.status == 0 || details.payment.status == 1 || details.payment.status == 3">
                                    <div class="col-md-6" *ngIf="details.payment.status == 0 || details.payment.status == 1 || details.payment.status == 3">
                                        <a class="btn btn-danger float-left" type="button" (click)="cancelPaymentClick(details.payment.id)">Cancelar Pagamento</a>
                                    </div>
                                    <div class="col-md-6" *ngIf="details.payment.status == 1 || details.payment.status == 3">
                                        <a class="btn btn-warning float-right" (click)="markAsPaidClick(details.payment.id)" type="button">Marcar como Pago</a>
                                    </div>
                                    <div class="col-md-6" *ngIf="details.payment.status == 0">
                                        <a class="btn btn-safe float-right" (click)="emitPayment(details.payment.id)" type="button">Emitir Pagamento</a>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Modal Modifiers -->
<ng-template #modifierModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Modificadores - #{{ connector.selectModifier }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form>
        <div class="form-group">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="active-tab" data-toggle="tab" href="#active" role="tab" aria-controls="active" aria-selected="true">Ativos</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="expired-tab" data-toggle="tab" href="#expired" role="tab" aria-controls="expired" aria-selected="false">Expirados</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="canceled-tab" data-toggle="tab" href="#cancelled" role="tab" aria-controls="cancelled" aria-selected="false">Cancelados</a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="active" role="tabpanel" aria-labelledby="active-tab">
                    <div class="row m-3">
                        <button type="button" class="btn btn-success float-left" (click)="connector.newModifier()">+</button>
                    </div>
                    <br>
                    <div class="row m-2" *ngFor="let modifier of connector.newModifiers; let i = index">
                        <div class="col-md-12">
                            <div class="card" style="height: 100%;">
                                <div class="card-body text-left" >
                                    <div class="row">
                                        <div class="col-md-2"><p><b>id: </b>{{ modifier.id }}</p></div>
                                        <div class="col-md-4"><p><b>Expira em: </b></p></div>
                                        <div class="col-md-6">
                                            <input class="form-control" type="date"
                                                [ngModelOptions]="{standalone: true}" 
                                                [(ngModel)]="modifier.expiration" ></div>
                                    </div>
                                    <hr>
                                    <div class="row"><div class="col-md-12">
                                        <input type="checkbox"
                                            id="discountCheck"
                                            [ngModelOptions]="{standalone: true}" [(ngModel)]="modifier.isDiscount"
                                            value="Desconto">
                                            <label for="discountCheck" class="p-1">Modificador de Desconto</label>
                                    </div></div>
                                    <div class="row">
                                        <div class="col-md-3"><b>Valor Aplicado (R$):</b></div>
                                        <div class="col-md-3">
                                            <input
                                             currencyMask [(ngModel)]="modifier.frontTextCurrency" 
                                             [ngModelOptions]="{standalone: true}"
                                             class="form-control"
                                             [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:'left' }"
                                            />
                                        </div>
                                        <div class="col-md-3"><b>Valor Aplicado (%): {{ modifier.frontEndPercentage }}</b></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12"><b>Justificativa: </b></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <textarea class="form-control"
                                            name=""  
                                            style="width: 100%;" 
                                            rows="3"
                                            [ngModelOptions]="{standalone: true}" [(ngModel)]="modifier.text"></textarea>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-md-6"><p><b>Criado Por:</b>{{ modifier.createdBy }}</p></div>
                                        <div class="col-md-6"><p><b>Modificado Por:</b>{{ modifier.modifiedBy }}</p></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6"><a class="btn btn-warning float-left" (click)="connector.addModifier(i)" type="button">Criar</a></div>
                                        <div class="col-md-6"><a class="btn btn-danger float-right" (click)="connector.removeNewModifier(i)" type="button">Cancelar</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row m-2" *ngFor="let modifier of connector.modifiers.active">
                        <div class="col-md-12">
                            <div class="card" style="height: 100%;">
                                <div class="card-body text-left" >
                                    <div class="row">
                                        <div class="col-md-2"><p><b>id: </b>{{ modifier.id }}</p></div>
                                        <div class="col-md-4"><p><b>Expira em: </b></p></div>
                                        <div class="col-md-6"><input class="form-control" type="date"
                                            [ngModelOptions]="{standalone: true}" 
                                            [(ngModel)]="modifier.expiration" ></div>
                                    </div>
                                    <hr>
                                    <div class="row"><div class="col-md-12">
                                        <input type="checkbox"
                                            id="discountCheck"
                                            [ngModelOptions]="{standalone: true}" [(ngModel)]="modifier.isDiscount"
                                            value="Desconto">
                                            <label for="discountCheck" class="p-1">Modificador de Desconto</label>
                                    </div></div>
                                    <div class="row">
                                        <div class="col-md-3"><b>Valor Aplicado (R$):</b></div>
                                        <div class="col-md-3">
                                            <input
                                             currencyMask [(ngModel)]="modifier.frontTextCurrency" 
                                             [ngModelOptions]="{standalone: true}"
                                             class="form-control"
                                             [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:'left' }"
                                            />
                                        </div>
                                        <div class="col-md-3"><b>Valor Aplicado (%): {{ modifier.frontEndPercentage }}</b></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12"><b>Justificativa: </b></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <textarea class="form-control"
                                            name=""  
                                            style="width: 100%;" 
                                            rows="3" 
                                            [ngModelOptions]="{standalone: true}" [(ngModel)]="modifier.text"></textarea>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-md-6"><p><b>Criado Por:</b>{{ modifier.createdBy }}</p></div>
                                        <div class="col-md-6"><p><b>Modificado Por:</b>{{ modifier.modifiedBy }}</p></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6"><a class="btn btn-warning float-left" (click)="connector.editModifier(modifier)" type="button">Editar</a></div>
                                        <div class="col-md-6"><a class="btn btn-danger float-right" (click)="connector.cancelModifier(modifier)" type="button">Cancelar</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="expired" role="tabpanel" aria-labelledby="expired-tab">
                    <br>
                    <div class="row m-2" *ngFor="let modifier of connector.modifiers.expired">
                        <div class="col-md-12">
                            <div class="card" style="height: 100%;">
                                <div class="card-body text-left" >
                                    <div class="row"><div class="col-md-12">
                                        <input type="checkbox"
                                            id="discountCheck"
                                            [ngModelOptions]="{standalone: true}" [(ngModel)]="modifier.isDiscount"
                                            value="Desconto">
                                            <label for="discountCheck" class="p-1">Modificador de Desconto</label>
                                    </div></div>
                                    <div class="row">
                                        <div class="col-md-6"><b>Valor Aplicado (R$): {{ modifier.frontTextCurrency | currency:'BRL':true:'1.2-2' }}</b></div>
                                        <div class="col-md-6"><b>Valor Aplicado (%): {{ modifier.frontEndPercentage }}</b></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12"><b>Justificativa: </b></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            {{ modifier.text }}
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-md-6"><p><b>Criado Por:</b>{{ modifier.createdBy }}</p></div>
                                        <div class="col-md-6"><p><b>Modificado Por:</b>{{ modifier.modifiedBy }}</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="cancelled" role="tabpanel" aria-labelledby="cancelled-tab">
                    <br>
                    <div class="row m-2" *ngFor="let modifier of connector.modifiers.cancelled">
                        <div class="col-md-12">
                            <div class="card" style="height: 100%;">
                                <div class="card-body text-left" >
                                    <div class="row">
                                        <div class="col-md-2"><p><b>id: </b>{{ modifier.id }}</p></div>
                                        <div class="col-md-4"><p><b>Expira em: </b></p></div>
                                        <div class="col-md-6"><input class="form-control" type="date"
                                            [ngModelOptions]="{standalone: true}" 
                                            [(ngModel)]="modifier.expiration" ></div>
                                    </div>
                                    <hr>
                                    <div class="row"><div class="col-md-12">
                                        <input type="checkbox"
                                            id="discountCheck"
                                            [ngModelOptions]="{standalone: true}" [(ngModel)]="modifier.isDiscount"
                                            value="Desconto">
                                            <label for="discountCheck" class="p-1">Modificador de Desconto</label>
                                    </div></div>
                                    <div class="row">
                                        <div class="col-md-6"><b>Valor Aplicado (R$): {{ modifier.frontTextCurrency | currency:'BRL':true:'1.2-2' }}</b></div>
                                        <div class="col-md-6"><b>Valor Aplicado (%): {{ modifier.frontEndPercentage }}</b></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12"><b>Justificativa: </b></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12">
                                            {{ modifier.text }}
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-md-6"><p><b>Criado Por:</b>{{ modifier.createdBy }}</p></div>
                                        <div class="col-md-6"><p><b>Modificado Por:</b>{{ modifier.modifiedBy }}</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
        </div>
      </form>
    </div>
</ng-template>

<!-- New Payment -->
<ng-template #newPaymentModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Criar um novo pagamento</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body p-3">
        <div class="row">
            <div class="col-md-6">
                <b>Valor (em R$):</b>
            </div>
            <div class="col-md-6">
                <input
                 currencyMask [(ngModel)]="connector.newPaymentForm.payment_amount" 
                 [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:'left' }"
                 class="form-control"
                />
            </div>
        </div>
        
        <div class="row mt-2">
            <div class="col-md-6">
                <b>Data de Expiração:</b>
            </div>
            <div class="col-md-6">
                <input class="form-control" type="date" [(ngModel)]="connector.newPaymentForm.expire_date">
            </div>
        </div>
        
        <div class="row mt-2">
            <div class="col-md-12">
                <b>Justificativa do Pagamento:</b>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <textarea class="form-control"
                    name=""  
                    style="width: 100%;" 
                    rows="3" 
                    step=".01" [(ngModel)]="connector.newPaymentForm.payment_text"
                ></textarea>
            </div>
        </div>
        <hr>
        <div class="row mt-3 float-right">
            <div class="col-md-12">
                <input class="btn btn-secondary float-right xbtn" (click)="createPayment()" type="button" value="Criar Cobrança">
            </div>
        </div>
    </div>
</ng-template>

<!-- Renegotiate -->
<ng-template #renegotiateModal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Renegociação de Dívida</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    
    <div class="modal-body p-5">
        <div *ngIf="!renegotiateDebtLoaded" style="margin:auto; height: 100%;">
            <div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
        <div *ngIf="renegotiateDebtLoaded">
            <div class="row">
                <div class="col-md-6">
                    <b>Valor Total Devido (em R$):</b>
                </div>
                <div class="col-md-6">
                    <input currencyMask 
                        [(ngModel)]="renegotiateForm.debt" class="form-control"
                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:'left' }"
                    />
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-6">
                    <b>Valor de Multa (em R$):</b>
                </div>
                <div class="col-md-6">
                    <input currencyMask 
                        [(ngModel)]="renegotiateForm.fine" class="form-control"
                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:'left' }"
                    />
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-6">
                    <b>Valor de Interesse (Juros Diário - em R$):</b>
                </div>
                <div class="col-md-6">
                    <input currencyMask 
                        [(ngModel)]="renegotiateForm.interests" class="form-control"
                        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:'left' }"
                    />
                </div>
            </div>
            
        </div>
        <hr>
        <div class="row mt-3 float-right">
            <div class="col-md-12">
                <input class="btn btn-secondary float-right xbtn" (click)="createNegotiation()" type="button" value="Emitir Negociação">
            </div>
        </div>
    </div>
</ng-template>