import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { HttpClientModule } from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ClientDashComponent } from './client-dash/client-dash.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyMaskModule } from "ng2-currency-mask";

import { AdminPageComponent } from './admin-dash/admin-page/admin-page.component';
import { IndicadoresComponent } from './admin-dash/indicadores/indicadores.component';

import { SpixViewComponent } from './admin-dash/spix_views/spix-view/spix-view.component';
import { SpixComponent } from './admin-dash/spix_views/spix/spix.component';

import { HarpyModelViewComponent } from './admin-dash/harpy_views/harpy-model-view/harpy-model-view.component';
import { HarpySearchComponent } from './admin-dash/harpy_views/harpy-search/harpy-search.component';
import { HarpyClientProductComponent } from './admin-dash/harpy_views/harpy-client-product/harpy-client-product.component';
import { HarpyCatalogComponent } from './admin-dash/harpy_views/harpy-catalog/harpy-catalog.component';
import { HarpySchemesComponent } from './admin-dash/harpy_views/harpy-schemes/harpy-schemes.component';
import { SchemeDetailViewComponent } from './admin-dash/scheme-detail-view/scheme-detail-view.component';
import { SchemeDetailClientlistTableComponent } from './admin-dash/scheme-detail-clientlist-table/scheme-detail-clientlist-table.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { ClientDashViewComponent } from './client-dash/client-dash-view/client-dash-view.component';
import { ClientUnifiedViewComponent } from './admin-dash/client-unified-view/client-unified-view.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ClientDashComponent,
    PageNotFoundComponent,
    AdminPageComponent,
    IndicadoresComponent,
    SpixComponent,
    SpixViewComponent,
    HarpyModelViewComponent,
    HarpySearchComponent,
    HarpyClientProductComponent,
    HarpyCatalogComponent,
    HarpySchemesComponent,
    SchemeDetailViewComponent,
    SchemeDetailClientlistTableComponent,
    ClientDashViewComponent,
    ClientUnifiedViewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    HttpClientModule,
    DataTablesModule,
    CurrencyMaskModule,
    NgApexchartsModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
