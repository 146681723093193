import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { clientModel, getInfoFromApiResponse } from '../common/clientModels'
import { faCheckCircle, faUserCircle, faMousePointer, faAddressCard, faLaughBeam } from '@fortawesome/free-solid-svg-icons';
import { faExclamationCircle, faLink, faClipboard, faDownload, faExclamationTriangle, faUmbrellaBeach, faQrcode } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { Title } from '@angular/platform-browser';

import { AuthenticatedGateway, LoginFailureTypes, ApiResponse } from "src/app/common/alex"

@Component({
  selector: 'app-client-dash',
  templateUrl: './client-dash.component.html',
  styleUrls: ['./client-dash.component.css']
})
export class ClientDashComponent implements OnInit {

  loaded = false


  userData: clientModel = undefined
  authenticatedGateway: AuthenticatedGateway;

  constructor(private router: Router, private titleService: Title) {
    console.info('     _  ________            ')
    console.info('  _-  _/  ____ /-________   ')
    console.info('   __ /  /___   /  ____ /   ')
    console.info(' --  /   ___/  /  /___      ')
    console.info(' -- /  /      /   ___/      ')
    console.info(' --/  /      /  /           ')
    console.info('_ /__/   __ /__/            ')
    console.info('   FiberFast - Dashboard V2 ')

    this.titleService.setTitle("FiberFast - Area do Cliente");
    // Authentication gateway
    this.authenticatedGateway = new AuthenticatedGateway(
      (data: AuthenticatedGateway) => this.onLoginSuccess(data),
      (data: LoginFailureTypes, msg: string | undefined) => this.onLoginFail(data, msg),
      (data: AuthenticatedGateway) => this.onAuthCheckRunCallback(data)
    );
    this.authenticatedGateway.checkToken();
  }
  ngOnInit(): void {}

  // Authenticated Gateway Callbacks
  onLoginSuccess(auth: AuthenticatedGateway) {
    this.pullData();
  }
  onLoginFail(failType: LoginFailureTypes, failMsg: string | undefined) {
    console.info('failed')
    this.router.navigate([''])
  }
  onAuthCheckRunCallback(auth: AuthenticatedGateway) {
    console.info('check was made')
  }

  doLogout = ()=>{
    this.authenticatedGateway.doLogout();
  }
  saveModifications = ()=>{
    let emailAddress = this.userData.clientMailAddress
    let phone1 = this.userData.clientPhone1
    let phone2 = this.userData.clientPhone2
    this.authenticatedGateway.authGet(
      `client_endpoints/saveClient?phone1=${phone1}&phone2=${phone2}&emailAddress=${emailAddress}`
    ).then(
      (apiResponse:ApiResponse)=>{
        let updatedEmailAddress = apiResponse.payload.cadastral_info.updated_data.customerContact.emailAddress
        let updatedPhone1 = apiResponse.payload.cadastral_info.updated_data.customerContact.phone1
        let updatedPhone2 = apiResponse.payload.cadastral_info.updated_data.customerContact.phone2
        let text = ''
        console.info(emailAddress, updatedEmailAddress)
        console.info(phone1, updatedPhone1)
        console.info(phone2, updatedPhone2)
        if (emailAddress != updatedEmailAddress){text += '<li>Email não foi alterado, verifique a formatação e tente novamente</li>'}
        if (Number(phone1) != Number(phone1)){text += '<li>Telefone 1 não foi alterado, verifique a formatação e tente novamente</li>'}
        if (Number(phone2) != Number(updatedPhone2)){text += '<li>Telefone 2 não foi alterado, verifique a formatação e tente novamente</li>'}
        if (text == ''){
          Swal.fire({
            title: 'Sucesso',
            text: 'As informações foram alteradas com sucesso, para efeitos, funcionais pedimos que aguarde até 2 horas',
            icon: 'success',
            timer: 3500,
            showConfirmButton: false,
            toast: true,
            position: 'top-right'
          })
          // Update UI
          this.userData.clientMailAddress = apiResponse.payload.cadastral_info.updated_data.customerContact.emailAddress
          this.userData.clientPhone1 = apiResponse.payload.cadastral_info.updated_data.customerContact.phone1
          this.userData.clientPhone2 = apiResponse.payload.cadastral_info.updated_data.customerContact.phone2
        }
        else{
          Swal.fire({
            title: 'Opps..',
            html: '<div class="text-left"><p>Houveram falhas durante o salvamento devido a formatação:</p><br><ul>'+text+'</ul></div>',
            icon: 'error',
            showConfirmButton: true
          })
        }
      }
    )
  }

  async pullData() {
    const apiResponse = await this.authenticatedGateway.authGet('client_endpoints/info');
    this.userData = getInfoFromApiResponse(apiResponse, false)
    this.loaded = true
  }

}
