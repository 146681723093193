import { Component, Input, OnInit } from '@angular/core';
import { AuthenticatedGateway } from 'src/app/common/alex';
import { clientModel, getInfoFromApiResponse } from 'src/app/common/clientModels';
import Swal from 'sweetalert2';

class ApiMultiFieldFind {
  results = []
  authenticatedGateway:AuthenticatedGateway
  filterOn: Array<string>
  endpoint: string

  constructor(authenticatedGateway:AuthenticatedGateway, endpoint:string, filterOn: Array<string>){
    this.authenticatedGateway = authenticatedGateway
    this.filterOn = filterOn
    this.endpoint = endpoint
  }
  async doFilter(match: string){
    let results = []
    for (let field of this.filterOn){
      let query = {}
      query[field] = `^${match}`
      let result = await this.authenticatedGateway.authPost('harpy/'+this.endpoint+'/query?useRegex=1&compact=1', query)
      results = [...results, ...result.payload.details]
    }
    this.results = results
  }
}

@Component({
  selector: 'app-client-unified-view',
  templateUrl: './client-unified-view.component.html',
  styleUrls: ['./client-unified-view.component.css']
})
export class ClientUnifiedViewComponent implements OnInit {
  @Input()  authenticatedGateway: AuthenticatedGateway;
  nothing = undefined
  allowStart = false
  apiMf: ApiMultiFieldFind;
  clientInput: string;
  selectedClient: string;
  loading: boolean = false;
  selectedClientModel: clientModel = undefined;

  constructor() {
  }

  ngOnInit(): void {
    this.apiMf = new ApiMultiFieldFind(this.authenticatedGateway,'customer', ['cpfCnpj', 'customerName'])
  }

  isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  clientKeyUp(){
    this.clientInput = this.clientInput.replace('.', '').replace('-', '')
    if(this.clientInput.length >= 3){
      this.apiMf.doFilter(this.clientInput)
    }
    this.clientInput = this.clientInput.replace('.', '').replace('-', '')
    if(this.isNumeric(this.clientInput))
      if(this.clientInput.length >= 11 || this.clientInput.length >= 11)
        this.allowStart = true
  }
  start(){
    this.clientInput = this.clientInput.replace('.', '').replace('-', '')
    if(this.isNumeric(this.clientInput))
      if(this.clientInput.length >= 11 || this.clientInput.length >= 11)
        this.selectClient(this.clientInput)
  }
  async selectClient(selectedClientKey){
    this.loading = true;
    this.selectedClient = selectedClientKey
    let apiResponse = await this.authenticatedGateway.authGet('/client_endpoints/managed_info?owner='+selectedClientKey)
    this.selectedClientModel = getInfoFromApiResponse(apiResponse, true)
    console.info(this.selectedClientModel)
    this.loading = false;
  }

  showError(){
    Swal.fire({
      title:'Opps...', 
      text:'Essa função não está disponivel na simulação',
      icon:'info', 
      timer: 3500,
      showConfirmButton: false,
      toast: true,
      position: 'top-right'
      }
    )
  }

}
