<body>
  <div class="loadbg" [hidden]="loaded">
      <div class="pulse"></div>
  </div>
  <div *ngIf="loaded">
    <div class="d-flex" id="wrapper">
      <!-- Sidebar -->
      <div class="bg-light border-right" id="sidebar-wrapper">
        <div class="sidebar-heading unselectable">
          <img width="100%" class="unselectable" src="assets/img/Logo-Vector_C_H.png">
        </div>
        <div class="list-group list-group-flush" id="sidebar-items">
          <a (click)="reset($event)" class="list-group-item list-group-item-action bg-light active" href="#indicadores" data-toggle="tab"><fa-icon [icon]="chartLine" class="mr-2" aria-hidden="true"></fa-icon>Indicadores</a>
          <!--a (click)="reset($event)" class="list-group-item list-group-item-action bg-light" href="#client-unified-view" data-toggle="tab"><fa-icon [icon]="desktop" class="mr-2" aria-hidden="true"></fa-icon>Analise do Cliente</a-->
          <a class="list-group-item list-group-item-action bg-light" href="#submenu1sub1" data-toggle="collapse" data-target="#submenu1sub1"><fa-icon [icon]="database" class="mr-2" aria-hidden="true"></fa-icon>Cadastros</a>
          <div class="collapse list-group list-group-flush" id="submenu1sub1" aria-expanded="false">
            <a (click)="reset($event)" class="pl-5 list-group-item list-group-item-action bg-light" href="#harpy-search" data-toggle="tab"><fa-icon [icon]="search" class="mr-2" aria-hidden="true"></fa-icon>Consultas</a>
            <a (click)="reset($event)" class="pl-5 list-group-item list-group-item-action bg-light" href="#harpy-client-product" data-toggle="tab"><fa-icon [icon]="user" class="mr-2" aria-hidden="true"></fa-icon>Clientes e Produtos</a>
            <a (click)="reset($event)" class="pl-5 list-group-item list-group-item-action bg-light" href="#harpy-catalog" data-toggle="tab"><fa-icon [icon]="book" class="mr-2" aria-hidden="true"></fa-icon>Catalogo</a>
            <a (click)="reset($event)" class="pl-5 list-group-item list-group-item-action bg-light" href="#harpy-schemes" data-toggle="tab"><fa-icon [icon]="building" class="mr-2" aria-hidden="true"></fa-icon>Pontos de Presença</a>
          </div>
          <a (click)="reset($event)" class="list-group-item list-group-item-action bg-light" href="#spix" data-toggle="tab"><fa-icon [icon]="moneyCheck" class="mr-2" aria-hidden="true"></fa-icon>Financeiro</a>
          <a (click)="reset($event)" class="list-group-item list-group-item-action bg-light" href="#schemes" data-toggle="tab"><fa-icon [icon]="wrench" class="mr-2" aria-hidden="true"></fa-icon>Pontos de Presença</a>
          <a (click)="reset($event)" class="list-group-item list-group-item-action bg-light" href="#client-unified-view" data-toggle="tab"><fa-icon [icon]="desktop" class="mr-2" aria-hidden="true"></fa-icon>Simular Área do Cliente</a>
        </div>
      </div>
      <!-- /#sidebar-wrapper -->
      <!-- Page Content -->
      <div id="page-content-wrapper">
        <nav class="navbar navbar-expand-lg navbar-light bg-light border-bottom">
          <button class="navbar-toggler-icon btn-nofocus" (click)="toggleWrapper()" style="padding: 0;border: none;" id="menu-toggle"></button>
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <span  class="ml-2 navbar-text unselectable" data-toggle="tooltip" title="Autenticado como {{displayUserRole}}">Olá {{displayUserName}}!</span>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
              <li class="nav-item">
                <a class="nav-link" href="#" (click)="doLogout()">Sair</a>
              </li>
            </ul>
          </div>
        </nav>
        <div class="container-fluid">
          <div class="tab-content" id="tab">
            <div class="tab-pane fade mt-2 show active" id="indicadores" role="tabpanel" aria-labelledby="indicadores-tab">
              <app-indicadores [(authenticatedGateway)]="auth_obj"></app-indicadores>
            </div>
            
            <div class="tab-pane fade mt-2" id="harpy-search" role="tabpanel" aria-labelledby="harpy-search-tab">
              <app-harpy-search [(authenticatedGateway)]="auth_obj"></app-harpy-search>
            </div>
            <div class="tab-pane fade mt-2" id="harpy-client-product" role="tabpanel" aria-labelledby="harpy-client-product-tab">
              <app-harpy-client-product [(authenticatedGateway)]="auth_obj"></app-harpy-client-product>
            </div>
            <div class="tab-pane fade mt-2" id="harpy-catalog" role="tabpanel" aria-labelledby="harpy-catalog">
              <app-harpy-catalog [(authenticatedGateway)]="auth_obj"></app-harpy-catalog>
            </div>
            <div class="tab-pane fade mt-2" id="harpy-schemes" role="tabpanel" aria-labelledby="harpy-schemes">
              <app-harpy-schemes [(authenticatedGateway)]="auth_obj"></app-harpy-schemes>
            </div>   
            <div class="tab-pane fade mt-2" id="spix" role="tabpanel" aria-labelledby="spix-tab">
              <app-spix [(authenticatedGateway)]="auth_obj"></app-spix>
            </div>
            <div class="tab-pane fade mt-2" id="schemes" role="tabpanel" aria-labelledby="schemes-tab">
              <app-scheme-detail-view [(authenticatedGateway)]="auth_obj"></app-scheme-detail-view>
            </div>
            <div class="tab-pane fade mt-2" id="client-unified-view" role="tabpanel" aria-labelledby="client-unified-view-tab">
              <app-client-unified-view [(authenticatedGateway)]="auth_obj"></app-client-unified-view>
            </div>
          </div>
            <!--div class="tab-content" id="tab">
              
              
              
                         
          </div-->
        </div>
      </div>
      <!-- /#page-content-wrapper -->
    </div>
  </div>

</body>

<!-- /#wrapper -->