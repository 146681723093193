
// Cookies
function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
function eraseCookie(name) {   
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

// API interfaces
export class ApiResponse {
    status:boolean | undefined = undefined
    payload:any | undefined = undefined
    error:any | undefined = undefined

    constructor(status: boolean | undefined, payload: object | undefined, error: object | undefined){
        this.status = status
        this.payload = payload 
        this.error = error
    }
}

class BackendRequester {
    apiURL:string = 'https://bff.fiberfast.com.br/v2'
    unauthHeaders: any = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    }
    
    constructor(){}
    buildAuthHeaders(token){
        let cpy = this.unauthHeaders
        cpy['alex-token'] = token
        return cpy
    }
    async tryCatchRequest(url:string, headers:any, payload:any, method:string): Promise<ApiResponse>{
        const settings = {
            method: method,
            headers: headers
        };
        if((payload !== undefined) && (payload !== null))
            settings['body'] = JSON.stringify(payload)
        
        try {
            const fetchResponse = await fetch(this.apiURL+url, settings);
            const data = await fetchResponse.json();
            if((fetchResponse.status >= 200) && (fetchResponse.status <= 300))
                return new ApiResponse(true, data, undefined);
            else
                return new ApiResponse(false, data, data);
        } catch (e) {
            return new ApiResponse(false, undefined, e);
        }
    }

    // Un-authenticated requests
    async unauthPost(url:string, data:any): Promise<ApiResponse>{
        return this.tryCatchRequest(url, this.unauthHeaders, data, 'POST')
    }
    async unauthGet(url:string): Promise<ApiResponse>{
        return this.tryCatchRequest(url, this.unauthHeaders, undefined, 'GET') 
    }
    async unauthPatch(url:string, data:any): Promise<ApiResponse>{
        return this.tryCatchRequest(url, this.unauthHeaders, data, 'PATCH') 
    }
    async unauthDelete(url:string, data:any): Promise<ApiResponse>{
        return this.tryCatchRequest(url, this.unauthHeaders, data, 'DELETE') 
    }

    // Authenticated requests
    async authPost(url:string, token:any, data:any): Promise<ApiResponse>{
        return this.tryCatchRequest(url, this.buildAuthHeaders(token), data, 'POST')
    }
    async authGet(url:string, token:any,): Promise<ApiResponse>{
        return this.tryCatchRequest(url, this.buildAuthHeaders(token), undefined, 'GET') 
    }
    async authPatch(url:string, token:any, data:any): Promise<ApiResponse>{
        return this.tryCatchRequest(url, this.buildAuthHeaders(token), data, 'PATCH') 
    }
    async authDelete(url:string, token:any, data:any): Promise<ApiResponse>{
        return this.tryCatchRequest(url, this.buildAuthHeaders(token), data, 'DELETE') 
    }
    

}

export enum LoginFailureTypes {
    Undef,
    BadRequest,
    UserNotLoggedIn
}

export class AuthenticatedGateway {
    isLoggedIn: boolean = false
    userTypeOnPolicy:string|undefined = undefined
    token: any = undefined
    info: any = {}
    apiClient: BackendRequester = new BackendRequester()
    callbackOnSuccess: any = undefined;
    callbackOnFailed: any = undefined;
    onCheckRunCallback: any = undefined;

    constructor(callbackOnSuccess:any, callbackOnFailed:any, onCheckRunCallback:any) {
        this.callbackOnSuccess = callbackOnSuccess;
        this.callbackOnFailed = callbackOnFailed;
        this.onCheckRunCallback = onCheckRunCallback;
    }

    getUserTypeOnPolicy(){
        return this.userTypeOnPolicy
    }

    resetAuth(){
        this.token = undefined
        this.info = undefined
        this.userTypeOnPolicy = undefined
        this.isLoggedIn = false
    }

    checkPolicy(policyName: string){
        if(this.isLoggedIn){
            for(let i of this.info['policies']['role_based']){
                if(i.policy_name == policyName)
                    return i.policy_value == 'yes'
            }
            for(let i of this.info['policies']['inline']){
                if(i.policy_name == policyName)
                    return i.policy_value == 'yes'
            }
        }
        return false
    }

    applyCallbacks(){
        if(!this.isLoggedIn){
            this.userTypeOnPolicy = 'failed'
            if(this.callbackOnFailed !== undefined){this.callbackOnFailed(LoginFailureTypes.UserNotLoggedIn, undefined);}
        }
        if(this.checkPolicy('dash_access')){
            this.userTypeOnPolicy = 'dash_access' 
            if(this.callbackOnSuccess !== undefined){this.callbackOnSuccess(this);}
        }
        if(this.checkPolicy('client_access')){
            this.userTypeOnPolicy = 'client_access' 
            if(this.callbackOnSuccess !== undefined){this.callbackOnSuccess(this);}    
        }
    }

    async checkToken(){
        if (getCookie("alex-token") != null) {
            let currToken = getCookie('alex-token')
            let response = await this.apiClient.authGet('/my-session', currToken)
            if(response.status){
                this.token = currToken
                this.isLoggedIn = true
                this.info = response.payload
            }
            else{
                eraseCookie('alex-token')
                this.resetAuth()
            }
        }
        else
            this.resetAuth()
        this.applyCallbacks()
    }

    async resetPassword(username: string): Promise<ApiResponse> {
        return this.apiClient.unauthPost('/password-reset-or-new-user', {
            "username": username       
        })
    }
    async applyPasswordChange(token: string, newPassword: string): Promise<ApiResponse> {
        return this.apiClient.unauthPost('/change-password', {
            "reset_token": token,
            "new_password": newPassword          
        })
    }
    async doLogin(username: string, password: string): Promise<ApiResponse> {
        if(this.isLoggedIn)
            return new ApiResponse(false, undefined, {message:'Usuário já está logado'})
        let response = await this.apiClient.unauthPost('/login', {
            "username": username,
            "password": password
        })
        if(response.status){
            setCookie('alex-token', response.payload['alex-token'], 2)
            setTimeout(
                ()=>{
                    this.checkToken()
                },2500
            )
        }
        else{
            if(this.callbackOnFailed !== undefined){
                this.callbackOnFailed(LoginFailureTypes.BadRequest, response.error.message);
            }
        }
        return response
    }
    async doLogout(){
        eraseCookie('alex-token')
        this.checkToken()
    }

    // Requests delegate
    async authPost(url:string, data:any): Promise<ApiResponse>{
        return this.apiClient.authPost('/api/'+url, this.token, data);
    }
    async authGet(url:string): Promise<ApiResponse>{
        return this.apiClient.authGet('/api/'+url, this.token);
    }
    async authPatch(url:string, data:any): Promise<ApiResponse>{
        return this.apiClient.authPatch('/api/'+url, this.token, data);
    }
    async authDelete(url:string, data:any): Promise<ApiResponse>{
        return this.apiClient.authDelete('/api/'+url, this.token, data);
    }
}