import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { spixConnector } from '../spix-view/spix-connector'
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';


import Swal from 'sweetalert2';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { AuthenticatedGateway } from 'src/app/common/alex';


declare var $:JQueryStatic;
@Component({
  selector: 'app-spix',
  templateUrl: './spix.component.html',
  styleUrls: ['./spix.component.css']
})

export class SpixComponent implements OnInit {
  @Input()  authenticatedGateway: AuthenticatedGateway;
  connector: spixConnector = undefined
  multiInputTemp: string = '';

  constructor(
    private http:HttpClient
  ){}

  ngOnInit(): void {
    this.connector = new spixConnector(this.authenticatedGateway)
  } 

  ngOnDestroy(): void {
  }

  enterMultiSearch():void{
    this.connector.multipleSearchForm.searchText.push(this.multiInputTemp)
    this.connector.multipleSearchForm.searchText = [...new Set(this.connector.multipleSearchForm.searchText)];
    this.multiInputTemp = ''
  }
  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData
    let pastedText = clipboardData.getData('text');
    let newLine = pastedText.split('\n')
    var filtered = newLine.filter(function(value, index, arr){ return value != '';});
    
    this.connector.multipleSearchForm.searchText = this.connector.multipleSearchForm.searchText.concat(filtered)
    this.connector.multipleSearchForm.searchText = [...new Set(this.connector.multipleSearchForm.searchText)];

    this.multiInputTemp = ''
    return false
  }

  clickOnSearchTerm(searchTerm):void{
    var a = this.connector.multipleSearchForm.searchText
    var filtered = a.filter(function(value, index, arr){ return value != searchTerm;});
    this.connector.multipleSearchForm.searchText = filtered
  }

  doUniqueSearch(): void {
    this.connector.singleSearch().then(e =>{
      if(e['status'] == false){
        Swal.fire({
          title:"Opps.", 
          text:e['details'], timer: 2000,
          showConfirmButton: false,
          toast: true,
          position: 'top-right'
        })
      }
    })
  }
  doMultipleSearch(): void {
    this.connector.multipleSearch().then(e =>{
      if(e['status'] == false){
        Swal.fire({
          title:"Opps.", 
          text:e['details'], timer: 2000,
          showConfirmButton: false,
          toast: true,
          position: 'top-right'
        })
      }
    })
  }

}
