<div class="card">
    <div class="card-body">
        <h5 class="card-title">Simulação da Área do Cliente</h5>
        <div *ngIf="apiMf !== undefined">
            <label>Procure o cliente e selecione da lista:</label>
            <input type="text" placeholder="CPF / CNPJ ou Nome do Cliente" (keyup)="clientKeyUp()" [(ngModel)]="clientInput" list="autoCompl1" autocomplete="off"
                class="form-control">
            <datalist id="autoCompl1">
                <option *ngFor="let option of apiMf.results" [id]="option.cpfCnpj" [value]="option.cpfCnpj">
                    {{option.customerName}}
                </option>
            </datalist>
        </div>
        <br>
        <input class="btn btn-primary" [disabled]="!allowStart" (click)="start()" type="button" value="Iniciar">
    </div>
</div>

<div class="d-flex flex-row justify-content-center mt-5" *ngIf="loading">
    <div class="pulse"></div>
</div>
<div *ngIf="(!loading) && (selectedClientModel != undefined)">
    <hr>
    <app-client-dash-view [(userData)]="selectedClientModel" [(saveModificationsCallback)]="showError"></app-client-dash-view>
    <hr>

</div>