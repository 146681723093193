<body>

	<div class="limiter">
		<section>
			<div class='air air1'></div>
			<div class='air air2'></div>
			<div class='air air3'></div>
			<div class='air air4'></div>
		<!--div class="container-login100" "-->
		<div class="container-login100">
			<div class="wrap-login100 fade-in-75">
				<form class="login100-form validate-form">
					<span class="login100-form-logo unselectable">
						<img width="100%" src="assets/img/Logo-Vector_C_H.png"/>
					</span>
					
					<span class="login100-form-title p-b-34 p-t-27 unselectable"></span>
					<div class="login-base" 
						[hidden]="viewTriggers['login-base'].hide"
						[ngClass]="{'fade-in': viewTriggers['login-base'].in, 'fade-out': viewTriggers['login-base'].out}"
					>
						<div class="wrap-input100 validate-input" data-validate = "Enter username">
							<input 
								class="input100" type="text" (keyup)="loginFormCheck()" (keypress)="checkCpfCnpj($event)" maxlength="18"
								mask="000.000.000-00||00.000.000/0000-00" 
								id="cpfCnpj" [(ngModel)]="username" name="cpfCnpj" required 
								placeholder="CPF/CNPJ">
							<span class="focus-input100" data-placeholder="&#xf207;"></span>
						</div>
	
						<div class="wrap-input100 validate-input" data-validate="Enter password">
							<input 
								class="input100" (keyup)="loginFormCheck()" type="password" 
								id="pass" [(ngModel)]="password" name="pass" required 
								placeholder="Senha">
							<span class="focus-input100" data-placeholder="&#xf191;"></span>
						</div>
						<div class="container-login100-form-btn">
							
							<button class="login100-form-btn" (click)="doLogin()" [disabled]="!loginFormIsOk || loginSpinner">
								<span><div class="lds-ring" [hidden]="!loginSpinner" ><div></div><div></div><div></div><div></div></div></span>
									Entrar
							</button>
						</div>
						<br>
						<div class="text-center p-t-50">
							<a class="txt1" (click)="triggerViewMode(1)">
								Primeiro login / Recupere sua senha
							</a>
						</div>
					</div>

					<div class="register-base" 
					[hidden]="viewTriggers['register-base'].hide" 
					[ngClass]="{'fade-in': viewTriggers['register-base'].in, 'fade-out': viewTriggers['register-base'].out}"
					>
						<div class="wrap-input100 validate-input" data-validate = "Enter username">
							<input 
								class="input100" type="text" (keypress)="checkCpfCnpj($event)" maxlength="18"
								mask="000.000.000-00||00.000.000/0000-00" 
								(keyup)="registerFormCheck()"
								id="cpfCnpj_1" [(ngModel)]="recoveryOrNewUsername" name="cpfCnpj_1" required 
								placeholder="CPF/CNPJ">
							<span class="focus-input100" data-placeholder="&#xf207;"></span>
						</div>
						
	
						<div class="container-login100-form-btn">
							<button class="login100-form-btn" (click)="doRecoverNew()" [disabled]="!recoverNewIsOk">
								Continuar
							</button>
						</div>
					</div>

					<div class="reset-pass-base"
					[hidden]="viewTriggers['reset-pass-base'].hide" 
					[ngClass]="{'fade-in': viewTriggers['reset-pass-base'].in, 'fade-out': viewTriggers['reset-pass-base'].out}"
					>
						<div class="wrap-input100 validate-input" data-validate="Enter password">
							<input 
								class="input100" (keyup)="passwordSetCheck()" name="passwordSet" type="password" 
								id="pass-set" [(ngModel)]="passwordSet" required 
								placeholder="Senha">
							<span class="focus-input100" data-placeholder="&#xf191;"></span>
						</div>
						<div class="wrap-input100 validate-input" data-validate="Enter password">
							<input 
								class="input100" (keyup)="passwordSetCheck()" name="passwordSetCheck" type="password" 
								id="pass-config" [(ngModel)]="passwordSetConfirm" required 
								placeholder="Repita sua senha">
							<span class="focus-input100" data-placeholder="&#xf191;"></span>
						</div>
						<div class="text-center p-t-50">
							<p class="txt1" style="color: brown;">{{passwordMessage}}</p>
						</div>
						<br>
						<div class="container-login100-form-btn">
							<button class="login100-form-btn" (click)="doPasswordSet()" [disabled]="!passwordSetIsOk">
								Continuar
							</button>
						</div>
					</div>

					<div class="go-back" [hidden]="viewTriggers['go-back'].hide" [ngClass]="{'fade-in': viewTriggers['go-back'].in, 'fade-out': viewTriggers['go-back'].out}">
						<br>
						<div class="text-center p-t-50">
							<a class="txt1" (click)="triggerViewMode(0)">
								Voltar
							</a>
						</div>
					</div>
				</form>
			</div>
		</div>
	</section>

	</div>
	
</body>