import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import {Title} from "@angular/platform-browser";

import Swal from 'sweetalert2'

import {AuthenticatedGateway, LoginFailureTypes, ApiResponse} from "../common/alex"

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  // ----- Init stuff
  auth_obj: AuthenticatedGateway = undefined;

  // ----- Login, register and reset stuff
  
  // Forms -- Login
  username = '';
  password = '';
  loginFormIsOk = false;
  loginSpinner = false;
  // Forms -- Recovery/New User
  recoveryOrNewUsername = '';
  recoverNewIsOk = false;
  // Forms -- Password Reset
  requestToken = '';
  passwordSet = '';
  passwordSetConfirm = '';
  passwordSetIsOk = false;
  passwordMessage = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private http:HttpClient, private router: Router, private titleService: Title
  ) {
    this.titleService.setTitle("FiberFast - Login");
    this.auth_obj = new AuthenticatedGateway(
      (data:AuthenticatedGateway)=>this.onLoginSuccess(data), 
      (data:LoginFailureTypes, msg:string|undefined)=>this.onLoginFail(data, msg),
      (data:AuthenticatedGateway)=>this.onAuthCheckRunCallback(data)
    );
  }
  
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: ParamMap) => {
      this.requestToken = params['reset_token'];
      if(this.requestToken !== undefined && this.requestToken != ''){
        // Is reset request
        this.triggerViewMode(2);
      }
      else{
        // Normal workflow
        this.auth_obj.checkToken();
        this.triggerViewMode(0);
      }
    });
  }

  // Callbacks
  onLoginSuccess(auth:AuthenticatedGateway){
    this.loginSpinner = true
    let fire = Swal.fire({
      title:"Usuário Logado!", 
      timer: 3000,
      text:"Aguarde enquanto te redirecionamos",
      showConfirmButton: false,
      toast: true,
      icon:'success',
      position: 'top-right'
    })
    setTimeout(()=>{
      if (this.auth_obj.userTypeOnPolicy == 'dash_access')
        this.router.navigate(['admin-dash'])
      else if (this.auth_obj.userTypeOnPolicy == 'client_access')
        this.router.navigate(['client-dash'])
      else{
        this.auth_obj.doLogout();
        this.router.navigate([''])
      }
    }, 2000)
    
  }
  onLoginFail(failType:LoginFailureTypes, failMsg:string|undefined){
    this.loginSpinner = false
    if(failType == LoginFailureTypes.UserNotLoggedIn){}
    if (failType == LoginFailureTypes.BadRequest)
      Swal.fire({
        title:"Opps", 
        text:failMsg, timer: 5000,
        showConfirmButton: false,
        toast: true,
        icon:'warning',
        position: 'top-right'
      })
    
  }
  onAuthCheckRunCallback(auth:AuthenticatedGateway){

  }

  doLogout(){
    localStorage.clear();
    window.location.href = '';
  }

  localStorageRedirect(){
    var userType = localStorage.getItem("FFDashUserType")
    if(userType == "admin"){
      //admin redirect
      this.router.navigate(['admin-dash']);
    }
    else {
      //user redirect
      this.router.navigate(['client-dash']);
    }
  }

  checkCpfCnpj(e: KeyboardEvent){
    const pattern = /[0-9]/;
    if (!pattern.test(String.fromCharCode(e.keyCode))) {    
        // invalid character, prevent input
        event.preventDefault();
    }
  }
  
  // Login bit
  doLogin(): void {
    this.loginSpinner = true
    this.auth_obj.doLogin(this.username, this.password).then(e=>{})
  }
  // Recovery bit
  doRecoverNew(): void {
    this.auth_obj.resetPassword(this.recoveryOrNewUsername).then(
      
      (apiResponse:ApiResponse)=>{
        if(!apiResponse.status){
          Swal.fire({
            title:"Opps", 
            text:apiResponse.error.message, timer: 5000,
            showConfirmButton: false,
            toast: true,
            icon:'warning',
            position: 'top-right'
          })
        }
        else{
          let email = apiResponse.payload.email_to;
          Swal.fire({
            title:"Sucesso", 
            html:"<h4>Sua senha foi encaminhada para o seu email: </h4><br><h5>"+email+"</h5>",
            showConfirmButton: true,
            icon:'success',
            position: 'center'
          })
        }
      }
    );
  }
  // Set password bit
  doPasswordSet(): void {
    if(this.requestToken != ''){
      this.auth_obj.applyPasswordChange(this.requestToken, this.passwordSet).then(
        (apiResponse:ApiResponse)=>{
          if(!apiResponse.status){
            Swal.fire({
              title:"Opps", 
              text:apiResponse.error.message, timer: 5000,
              showConfirmButton: false,
              toast: true,
              icon:'warning',
              position: 'top-right'
            })
          }
          else{
            Swal.fire({
              title:"Sucesso", 
              text:"Sua senha foi alterada", timer: 2000,
              showConfirmButton: false,
              toast: true,
              icon:'success',
              position: 'top-right'
            })
            setTimeout(()=>{this.router.navigate([''])}, 3000)
          }
        }
      )
    }
  }
  // ----- UI stuff

  // Button Checkers
  loginFormCheck(): void{
    this.loginFormIsOk = (this.username.length >= 11 && this.password.length > 0) ? true : false
  }
  registerFormCheck(): void{
    this.recoverNewIsOk = (this.recoveryOrNewUsername.length >= 11) ? true : false
  }
  passwordSetCheck(): void {
    this.passwordSetIsOk = false;
  
    // Check if the password length is less than 8 characters
    if (this.passwordSet.length < 8) {
      this.passwordMessage = 'Sua senha deve conter no mínimo 8 caracteres';
    } 
    // Check if the password contains at least one uppercase letter
    else if (!/[A-Z]/.test(this.passwordSet)) {
      this.passwordMessage = 'Sua senha deve conter pelo menos uma letra maiúscula';
    } 
    // Check if the password contains at least one lowercase letter
    else if (!/[a-z]/.test(this.passwordSet)) {
      this.passwordMessage = 'Sua senha deve conter pelo menos uma letra minúscula';
    } 
    // Check if the password contains at least one digit
    else if (!/[0-9]/.test(this.passwordSet)) {
      this.passwordMessage = 'Sua senha deve conter pelo menos um número';
    } 
    // Check if the password contains at least one special character
    else if (!/[!@#$%^&*()\+\-\'"?~`;\/\\|:_=.,<>{}\[\]]/.test(this.passwordSet)) {
      this.passwordMessage = 'Sua senha deve conter pelo menos um caractere especial';
    } 
    // Check if the password contains spaces
    else if (/\s/.test(this.passwordSet)) {
      this.passwordMessage = 'Sua senha não pode conter espaços em branco';
    }
    // Check if the password matches the confirmation
    else if (this.passwordSet !== this.passwordSetConfirm) {
      this.passwordMessage = 'Senhas não coincidem';
    } 
    // If all checks pass, consider the password as strong
    else {
      this.passwordSetIsOk = true;
      this.passwordMessage = '';
    }
  }

  // Triggers for multiple views
  viewTriggers = { 
    'login-base':{'in':false, 'out':false, 'hide':false},
    'register-base':{'in':false, 'out':false, 'hide':false},
    'reset-pass-base':{'in':false, 'out':false, 'hide':false},
    'go-back':{'in':false, 'out':false, 'hide':false}
  }
  // Stores states of all views
  allViews = {
    'login-base':1, 'register-base':1, 'reset-pass-base':1, 'go-back':1
  }
  // Elements to show in each state
  statesClasses = [
    ['login-base'], 
    ['register-base','go-back'],
    ['reset-pass-base']
  ]
  // Method to trigger animation n' shit
  triggerViewMode(mode): void{
    var toHide = []
    const viewKeys = this.statesClasses[mode]
    Object.keys(this.allViews).forEach(
      e => {
        if(this.allViews[e] == 1){
          this.viewTriggers[e].in = false
          this.viewTriggers[e].out = true
          toHide.push(e)
          this.allViews[e] = 0
        }	
      }
    )
    toHide.forEach(e => setTimeout(() => {
      this.viewTriggers[e].hide = true
      viewKeys.forEach(e_ => {
        this.allViews[e_] = 1
        this.viewTriggers[e_].hide = false
        this.viewTriggers[e_].out = false
        this.viewTriggers[e_].in = true
      })
    }),600)

  }

}
