import { AuthenticatedGateway } from '../../common/alex';

export class Modifier {
    _showPercent: boolean;
    _showPercentTxt: string;
    _isDiscount: boolean;

    constructor(
        public id:string,
        public planValue:number, 
        public modifierValue:number, 
        public expiration:string,
        public text:string, 
        public createdBy:string, 
        public modifiedBy:string, 
    ){
        this._isDiscount = (modifierValue <= 0)
    }

    public set isDiscount(value:boolean){
        this._isDiscount = value
        this.modifierValue = (Math.abs(this.modifierValue))*((-1)*Number(this._isDiscount) + (1)*Number(!this._isDiscount))
    }
    public get isDiscount():boolean{
        return this._isDiscount
    }
    
    public get frontTextCurrency():string{
        return String(Math.abs(this.modifierValue*this.planValue))
    }

    public get frontEndPercentage():string{
        return String((this.modifierValue * 100).toFixed(2)).replace('.', ',')
    }

    public set frontTextCurrency(value){
        console.info(value)
        var numVal = Number(value)
        this.modifierValue = numVal/this.planValue
        if(this._isDiscount){
            this.modifierValue = (Math.abs(this.modifierValue))*((-1)*Number(this._isDiscount) + (1)*Number(!this._isDiscount))
        }
    }
}

export class spixCalls {
    authenticatedGateway: AuthenticatedGateway;

    constructor(authenticatedGateway: AuthenticatedGateway) {
        this.authenticatedGateway = authenticatedGateway
    }

    async makeGetAuthenticatedRequestWrap(url: string):Promise<any> {
        let response_api = await this.authenticatedGateway.authGet(url);
        if (response_api.status)
            return response_api.payload;
        else
            return {'status':false, details:'Internal Server Error'}
    }

    async makePostAuthenticatedRequestWrap(url: string, data:any):Promise<any> {
        let response_api = await this.authenticatedGateway.authPost(url, data);
        if (response_api.status)
            return response_api.payload;
        else
            return {'status':false, details:'Internal Server Error'}
    }

    async makeDeleteAuthenticatedRequestWrap(url: string, data:any):Promise<any> {
        let response_api = await this.authenticatedGateway.authDelete(url, data);
        if (response_api.status)
            return response_api.payload;
        else
            return {'status':false, details:'Internal Server Error'}
    }
    async makePatchAuthenticatedRequestWrap(url: string, data:any):Promise<any> {
        let response_api = await this.authenticatedGateway.authPatch(url, data);
        if (response_api.status)
            return response_api.payload;
        else
            return {'status':false, details:'Internal Server Error'}
    }

    async numbers():Promise<any>{
        var sub = await this.makeGetAuthenticatedRequestWrap('spix/api/subscriptions/report')
        var pays = await this.makeGetAuthenticatedRequestWrap('spix/api/payments/report')
        return {sub:sub['content'][0], pays:pays['content'][0]}
    }

    async getMultipleCpfCnpj(cpfCnpjs:Array<string>): Promise<any>{
        return await this.makePostAuthenticatedRequestWrap(
            'spix/api/subscriptions/query?bring_defaulted=1&bring_blocked=1&bring_inactive=1',
            {clients:cpfCnpjs}
        )
    }
    async getCpfCnpjSubs(cpfCnpj: string): Promise < any > {
        return await this.makeGetAuthenticatedRequestWrap(
            'spix/api/subscriptions/client/' + cpfCnpj + '?bring_defaulted=1&bring_blocked=1&bring_inactive=1'
        )
    }

    async getProductIdSubs(productId: string): Promise < any > {
        return await this.makeGetAuthenticatedRequestWrap(
            'spix/api/subscriptions/plan/' + productId
        )
    }

    async getCancelationMotive(subscriptionId: string): Promise < any > {
        return await this.makeGetAuthenticatedRequestWrap(
            'spix/api/subscriptions/cancelationDetails/' + subscriptionId
        )
    }

    async getPaymentsForSubscription(subsId: string): Promise < any > {
        return await this.makeGetAuthenticatedRequestWrap(
            'spix/api/payments/subscription/' + subsId + '?bring_defaulted=1&bring_cancelled=1&bring_paid=1'
        )
    }
    
    async getPaymentDetails(paymentId: string): Promise < any > {
        return await this.makeGetAuthenticatedRequestWrap(
            'spix/api/payments/details/' + paymentId
        )
    }

    async cancelPayment(paymentId: string): Promise<any>{
        let id = await this.getOperatorId()
        let body = {
            "delete_form":{
                "operatorId":id,
            }
        }
        let d = await this.makeDeleteAuthenticatedRequestWrap('spix/api/payments/'+paymentId, body)
        return d.content[0]
    }
    async createNewPayment(form: any): Promise<any>{
        let id = await this.getOperatorId()
        let body = {
            "payment":{
                "operatorId":id,
                "fromSubscriptionId":form.fromSubscriptionId,
                "expire_date":form.expire_date,
                "payment_amount":form.payment_amount,
                "payment_text":form.payment_text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            }
        }
        let d = await this.makePostAuthenticatedRequestWrap('spix/api/payments', body)
        return d.content[0]
    }

    async emitNewPayment(id: string): Promise < any > {
        let d = await this.makeGetAuthenticatedRequestWrap(
            'spix/api/payments/emitPayment/' + id
        )
        return {'status':true, detail:d['content'][0]}
    }

    async markAsPaid(id: string): Promise < any > {
        let d = await this.makeGetAuthenticatedRequestWrap(
            'spix/api/payments/settle/' + id
        )
        return {'status':true, detail:d['content'][0]}

    }

    async getModifiersForSubscription(subsId: string, planValue: number): Promise < any > {
        let d = await this.makeGetAuthenticatedRequestWrap(
            'spix/api/modifiers/subscription/' + subsId + '?bring_expired=1&bring_cancelled=1'
        )
        const f = (e) => {
            return new Modifier(
                e.id,
                planValue,
                e.modifier_value,
                e.expiration_date.split('T')[0],
                e.modifier_text,
                e.createdById,
                e.changedById
            )
        }
        d.active = d.active.map(f)
        d.expired = d.expired.map(f)
        d.cancelled = d.cancelled.map(f)
        return d
    }

    private async getOperatorId(){
        return await this.authenticatedGateway.info.username;
    }

    async saveModifier(modifier:Modifier){
        let id = await this.getOperatorId()
        let body = {
            "changes":{
                "operatorId":id,
                "modifier_value":modifier.modifierValue,
                "expiration_date":modifier.expiration,
                "modifier_text":modifier.text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            }
        }
        let d = await this.makePatchAuthenticatedRequestWrap('spix/api/modifiers/'+modifier.id, body)
        modifier.modifierValue = d.content[0].content.modifier_value
        modifier.modifiedBy = d.content[0].content.changedById
        modifier.text = d.content[0].content.modifier_text
        return d.content[0]
    }
    async cancelModifier(modifier:Modifier){
        let id = await this.getOperatorId()
        let body = {
            "delete_form":{
                "operatorId":id,
            }
        }
        let d = await this.makeDeleteAuthenticatedRequestWrap('spix/api/modifiers/'+modifier.id, body)
        return d.content[0]
    }
    async createModifier(modifier:Modifier, subscriptionId:any){
        let id = await this.getOperatorId()
        let body = {
            "modifier":{
                "operatorId":id,
                "subscriptionId":subscriptionId,
                "expiration_date":modifier.expiration,
                "modifier_value":modifier.modifierValue,
                "modifier_text":modifier.text.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            }
        }
        let d = await this.makePostAuthenticatedRequestWrap('spix/api/modifiers', body)
        return d.content[0]
    }

    async getRenegotiateForm(subscriptionId: string | number){  
        let d = await this.makeGetAuthenticatedRequestWrap(
            'spix/api/payments/calculateDebt/' + subscriptionId
        )
        return d.content[0]
    }

    async emitRenegotiation(subscriptionId: string|number, form:any){

        var id = await this.getOperatorId()

        var formSend = Object.assign({}, form)
        
        formSend['debt'] = formSend['debt']
        formSend['fine'] = formSend['fine']
        formSend['interests'] = formSend['interests']

        return await this.makePostAuthenticatedRequestWrap(
            'spix/api/payments/emitDebt/'+subscriptionId,
            {debtForm:formSend}
        )
    }
}