import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticatedGateway } from 'src/app/common/alex';

import Swal from 'sweetalert2';



@Component({
  selector: 'app-harpy-model-view',
  templateUrl: './harpy-model-view.component.html',
  styleUrls: ['./harpy-model-view.component.css']
})
export class HarpyModelViewComponent implements OnInit {
  
  @Input() authenticatedGateway: AuthenticatedGateway;
  @Input() harpyModel: string;
  @Input() searchPlaceholder: string;
  @Input() multiIndexSearch: Array<string>;
  @Input() canDynamicAdd: Array<string>;
  
  @ViewChild('newDocumentModal') newDocumentModal: any;
  @ViewChild('newAuxModal') newAuxModal: any;

  Object = Object
  schema = {}
  tableSchema = {}
  harpyLoading = false
  searchValue = ''
  searchReturn = []
  searchResultFull = []
  realtimeSearchBox = ''
  realtimeSearchId = ''
  table_ready = false

  httpOptions = {
      headers: new HttpHeaders({ 
        'Access-Control-Allow-Origin':'*',
      })
  };

  closeResult = '';
  constructor(
    private http:HttpClient,
  ) {
      
  }

  ngOnInit(): void {
    this.authenticatedGateway.authGet('harpy/'+this.harpyModel+'/getSchema').then(
      (d) => {
        this.schema = d.payload
        this.buildTableSchema()
      }
    ).catch(
      (e) => {
        console.info(e)
      }
    )
  }
  
  isValidData(val): boolean { 
    if(typeof val === 'object'){
      return !Object.keys(val).includes('_id')
    }
    return !(typeof val === 'object' && val !== null) 
  }

  toReduce(reduceObject):object{
      let toRet = {}
      for(let eK in reduceObject){
          let e = reduceObject[eK]
          if((eK != '__v') && (eK != '_id') && (eK != 'createdAt')){
            if(typeof e === 'object' && e !== null){
                if(!Object.keys(e).includes('_id')){
                    toRet = {...toRet, ...this.toReduce(e)}
                }
            }
            else{
                toRet[eK] = e
            }  
          }
          
      }
      return toRet
  }

  toReduceSchema(reduceObject):object{
    let toRet = {}
    for(let eK in reduceObject){
        let e = reduceObject[eK]
        let t = e['type']
        if(typeof t === 'object' && t !== null){
            toRet = {...toRet, ...this.toReduceSchema(t)}
        }
        else{
          if(!t.includes('schema_foreign'))
            toRet[e['frontEndName']] = eK
          else{
            if(e['toShowSubId'] !== undefined){
              toRet[e['frontEndName']] = {'parentKey':eK, 'childKey':e['toShowSubId']}
            }
          }
        }  
    }
    return toRet
}

  buildTableSchema(){
    this.tableSchema = this.toReduceSchema(this.schema)
  }

  endQuery(results){
    this.harpyLoading = false
    for(let idx in results.details){
      this.searchResultFull.push(results.details[idx])
      let prepush = this.toReduce(results.details[idx])
      let toInclude = {}
      for(let k in this.tableSchema){
        if(typeof this.tableSchema[k] !== 'object'){
          toInclude[this.tableSchema[k]] = prepush[this.tableSchema[k]]
        }
        else{
          let evaluatingObj = this.tableSchema[k]
          let parentKey = evaluatingObj.parentKey
          let childKey = evaluatingObj.childKey
          toInclude[parentKey+'_'+childKey] = results.details[idx][parentKey]
        }
      }
      this.searchReturn.push(toInclude)
    }
  }

  postQuery(obj){
    this.harpyLoading = true
    this.table_ready = false;
    this.authenticatedGateway.authPost(
      'harpy/'+this.harpyModel+'/query?useRegex=1&compact=1',
      obj
    ).then(
      (d) => {
        d = d.payload
        if(d.status)
          this.endQuery(d)
        if(this.searchReturn.length > 0){
          this.table_ready = true;
        }
      }
    ).catch(
      (e) => {
        console.info(e)
      }
    )
  }

  onSearchChange(){
    if(this.searchValue != ""){
      this.searchReturn = []
      for(let idx in this.multiIndexSearch){
        var key = this.multiIndexSearch[idx]
        var obj = {}
        obj[key] = this.searchValue
        this.postQuery(obj)
      }
    }
  }
  
  realtimeSearch(){}
  logTest(){
  }
}
