import { Component, Input, OnInit } from '@angular/core';
import { clientModel } from '../../common/clientModels';
import Swal from 'sweetalert2';
// Icons
import { faCheckCircle, faUserCircle, faMousePointer, faAddressCard, faLaughBeam } from '@fortawesome/free-solid-svg-icons';
import { faExclamationCircle, faLink, faClipboard, faDownload, faExclamationTriangle, faUmbrellaBeach, faQrcode } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-client-dash-view',
  templateUrl: './client-dash-view.component.html',
  styleUrls: ['./client-dash-view.component.css']
})
export class ClientDashViewComponent implements OnInit {
  @Input()  userData: clientModel;
  @Input()  exitCallback: Function;
  @Input()  saveModificationsCallback: Function;
  
  phone = '(00) 0000-0000';
  phoneNineDig = '(00) 00000-0000';
  phoneCountryCode = '+55 (00) 0000-0000';
  phoneNineDigCountryCode = '+55 (00) 00000-0000';

  checkCircle = faCheckCircle
  exclamationCircle = faExclamationCircle
  linkIcon = faLink
  clipboard = faClipboard
  download = faDownload
  qrcode = faQrcode
  exclamationTriangle = faExclamationTriangle
  userCircle = faUserCircle
  mousePointer = faMousePointer
  addressCard = faAddressCard
  cheers = faLaughBeam
  accessedPaymentsOnce = false

  constructor() {}

  ngOnInit(): void {
  }

  // Functions
  displayPIX(qrCodeImg, qrCodeText): void {
    Swal.fire({
      title: '<strong>PIX</strong>',
      html:
        '<b>Pague com o QR Code ou utilize o copie e cola:</b>' +
        '<br>'+
        '<img src="'+qrCodeImg+'">',
      showCloseButton: true,
      showCancelButton: true,
      showLoaderOnConfirm: true,
      focusConfirm: false,
      confirmButtonText:'Copiar Código PIX',
      confirmButtonAriaLabel: 'Copiar PIX',
      cancelButtonText:'Fechar',
      cancelButtonAriaLabel: 'Fechar tela PIX'
    }).then((result) => {
      if(result.isConfirmed)
        this.copyMessage(qrCodeText);
    })
  }

  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    Swal.fire({
      title: 'Texto copiado!',
      icon: 'success',
      toast: true,
      timer: 2500,
      timerProgressBar: true,
      showConfirmButton: false,
      position: 'top-end',
    })
  }

}
