


<div class="row">
    <div class="col-md-12">
        <input type="text" (change)="onSearchChange()" [(ngModel)]="searchValue" [attr.placeholder]="searchPlaceholder" class="form-control">
    </div>
</div>

<div class="row mt-3">
    <div class="col-md-12">
        <div class="loader" *ngIf="harpyLoading"></div>
    </div>
</div>

<div class="row mt-3">
    <div *ngFor="let ret of searchReturn; let idx = index">
        <ng-container *ngTemplateOutlet="searchResult; context:{ $implicit:{data: ret, idx:idx} }"></ng-container>
    </div>
</div>


<table *ngIf="table_ready" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover" [attr.id]="randomTableId">
    <thead>
      <tr>
        <th *ngFor="let key of Object.keys(tableSchema);">
            {{key}}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let ret of searchReturn">
        <td *ngFor="let key of Object.keys(ret);">
            {{ret[key]}}
        </td>
      </tr>
    </tbody>
</table>