import { HttpClient } from '@angular/common/http';
import { ApiResponse, AuthenticatedGateway } from './alex';


export class productModel {
  constructor(
    public connectionId,
    public password: string,
    public productAssignedPrice: number,
    public productInnerSchemeId: string,
    public productName: string,
    public productPaymentDue: number,
    public productStatusAlive: boolean,
    public productStatusActive: boolean,
    public schemeStatus: boolean,
    public subs: boolean
  ) {}

}

export class clientModel {
  constructor(
    public cpfCnpj: string,
    public clientName: string,
    public clientPhone1: string,
    public clientPhone2: string,
    public clientMailAddress: string,
    public clientZip: string,
    public clientCity: string,
    public clientState: string,
    public clientAddress: string,
    public clientComplement: string,
    public products: Array<productModel>
  ) {}
}

export function getInfoFromApiResponse(apiResponse: ApiResponse, filterCanceled:boolean){
  if (apiResponse) {
    const {
      cadastral_info: { 
        customerData: d, 
        productData: p,
        subscription_info: s,
        payment_info: pays
      }
    } = apiResponse.payload;

    // Populate Products
    const subs = {};
    ['active', 'blocked', 'defaulted'].forEach(
      (status) => {
        s[status].forEach((sub) => {
          sub.status_text = status;
          sub.payments = {}
          subs[sub.id] = sub;
        });
      }
    );
    let valid_pay_status = ['open', 'cancelled', 'defaulted', 'paid']

    if (filterCanceled)
      valid_pay_status = ['open', 'defaulted', 'paid']
      
    for (const status of valid_pay_status) {
      for (const sub_id in pays[status]) {
        const pay_obj = pays[status][sub_id];
        subs[sub_id]['payments'][status] = pay_obj.map((x: any) => ({ status_text: status, ...x }))
      }
    }

    // Reindex the subs
    const subs_reindex = [];
    for (const sub_idx in subs) {
      subs_reindex[subs[sub_idx].plan_id] = subs[sub_idx];
    }

    const prods = p.map((product) =>
    {
        let subs = subs_reindex[product.connectionId] 
        if(subs === undefined)
          subs = {'status_text':'active', payments:{'open':[], 'defaulted':[], 'paid':[]}}
        return new productModel(
          product.connectionId,
          product.password,
          product.productAssignedPrice,
          product.productInnerSchemeId,
          product.productName,
          product.productPaymentDue,
          product.productStatus.isAlive,
          product.productStatus.isActive,
          product.schemeStatus,
          subs
        )
      }
    );
    return new clientModel(
      d.cpfCnpj,
      d.customerName,
      d.customerContact.phone1,
      d.customerContact.phone2,
      d.customerContact.emailAddress,
      d.customerAddress.zip,
      d.customerAddress.city,
      d.customerAddress.state,
      `${d.customerAddress.street}, ${d.customerAddress.number} - ${d.customerAddress.neighborhood}`,
      d.customerAddress.complement,
      prods
    )
  }
}

