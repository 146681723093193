    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li class="nav-item">
            <a class="nav-link active" id="clients-tab" data-toggle="pill" href="#clients" role="tab" aria-controls="clients" aria-selected="true">Clientes</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="products-tab" data-toggle="pill" href="#products" role="tab" aria-controls="products" aria-selected="false">Produtos</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="scheme-tab" data-toggle="pill" href="#scheme" role="tab" aria-controls="scheme" aria-selected="false">Schemes</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="catalog-tab" data-toggle="pill" href="#catalog" role="tab" aria-controls="catalog" aria-selected="false">Catálogo</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" id="scheme-backend-tab" data-toggle="pill" href="#scheme-backend" role="tab" aria-controls="scheme-backend" aria-selected="false">Scheme Backend</a>
        </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="clients" role="tabpanel" aria-labelledby="clients">
            <app-harpy-model-view [(authenticatedGateway)]="authenticatedGateway" [(multiIndexSearch)]="clientMultiIndexSearch" [(searchPlaceholder)]="clientPlaceholderName" [(harpyModel)]="clientsModelName"></app-harpy-model-view>
        </div>
        <div class="tab-pane fade" id="products" role="tabpanel" aria-labelledby="products-tab">
            <app-harpy-model-view [(authenticatedGateway)]="authenticatedGateway" [(multiIndexSearch)]="productMultiIndexSearch" [(searchPlaceholder)]="productPlaceholderName" [(harpyModel)]="productModelName"></app-harpy-model-view>
        </div>
        <div class="tab-pane fade" id="scheme" role="tabpanel" aria-labelledby="scheme-tab">
            <app-harpy-model-view [(authenticatedGateway)]="authenticatedGateway" [(multiIndexSearch)]="schemeMultiIndexSearch" [(searchPlaceholder)]="schemePlaceholderName" [(harpyModel)]="schemeModelName"></app-harpy-model-view>
        </div>
        <div class="tab-pane fade" id="catalog" role="tabpanel" aria-labelledby="catalog-tab">
            <app-harpy-model-view [(authenticatedGateway)]="authenticatedGateway" [(multiIndexSearch)]="catalogMultiIndexSearch" [(searchPlaceholder)]="catalogPlaceholderName" [(harpyModel)]="catalogModelName"></app-harpy-model-view>
        </div>
        <div class="tab-pane fade" id="scheme-backend" role="tabpanel" aria-labelledby="scheme-backend-tab">
            <app-harpy-model-view [(authenticatedGateway)]="authenticatedGateway" [(multiIndexSearch)]="schemeBackendMultiIndexSearch" [(searchPlaceholder)]="schemeBackendPlaceholderName" [(harpyModel)]="schemeBackendModelName"></app-harpy-model-view>
        </div>
    </div>

