import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Router, ActivatedRoute, Params} from '@angular/router';
import Swal from 'sweetalert2';
import { Title } from '@angular/platform-browser';
import { 
  faSearch, faUser, faMoneyBill, faChartLine, faBuilding, faBook, faUserCog, faWrench, faAlignJustify,
  faDatabase, faDesktop
} from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';

import {AuthenticatedGateway, LoginFailureTypes, ApiResponse} from "../../common/alex"


@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.css']
})
export class AdminPageComponent implements OnInit {
  token: string = '';

  search = faSearch
  database = faDatabase
  desktop = faDesktop
  user = faUser
  moneyCheck = faMoneyBill
  chartLine = faChartLine
  building = faBuilding
  book = faBook
  userCog = faUserCog
  wrench = faWrench
  alignJustify = faAlignJustify
  displayUserName = ''
  displayUserRole = ''

  toggleSidebar = false;
  loaded = false;
  auth_obj: AuthenticatedGateway = undefined;

  constructor(
    private router: Router, private titleService: Title) 
    {
      console.info('     _  ________            ')
      console.info('  _-  _/  ____ /-________   ') 
      console.info('   __ /  /___   /  ____ /   ')
      console.info(' --  /   ___/  /  /___      ')
      console.info(' -- /  /      /   ___/      ')
      console.info(' --/  /      /  /           ')
      console.info('_ /__/   __ /__/            ')
      console.info('   FiberFast - Dashboard V2 ')
        
      this.titleService.setTitle("FiberFast - Dashboard");
      // Authentication gateway
      this.auth_obj = new AuthenticatedGateway(
        (data:AuthenticatedGateway)=>this.onLoginSuccess(data), 
        (data:LoginFailureTypes, msg:string|undefined)=>this.onLoginFail(data, msg),
        (data:AuthenticatedGateway)=>this.onAuthCheckRunCallback(data)
      );
    }

    ngOnInit(): void {
      //Toggle Click Function
      $("#menu-toggle").click(function(e) {
        e.preventDefault();
        $("#wrapper").toggleClass("toggled");
      });
      this.auth_obj.checkToken();
    }

  // Authenticated Gateway Callbacks
  onLoginSuccess(auth:AuthenticatedGateway){
    this.displayUserName = this.auth_obj.info.additional_info.name.split(' ')[0];
    this.displayUserRole = this.auth_obj.info.additional_info.role;
    this.loaded = true;
  }
  onLoginFail(failType:LoginFailureTypes, failMsg:string|undefined){
    console.info('failed')
    this.router.navigate([''])
  }
  onAuthCheckRunCallback(auth:AuthenticatedGateway){
    console.info('check was made')
  }

  doLogout(){
    this.auth_obj.doLogout();
  }

  toggleWrapper(): void{
    $("#wrapper").toggleClass("toggled");
  }

  reset(event){
    $(".list-group-item.active").removeClass("active")
    $(event).addClass("active");
  }

}
