import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-scheme-detail-clientlist-table',
  templateUrl: './scheme-detail-clientlist-table.component.html',
  styleUrls: ['./scheme-detail-clientlist-table.component.css']
})
export class SchemeDetailClientlistTableComponent implements OnInit {
  @Input()  content: Array<Array<string>>;
  @Output() onTableSelect: EventEmitter<any> = new EventEmitter();
  @Input()  hasCallback: boolean;
  @Input()  buttonText: string;

  loadedSchema = {}
  loadedContent = [['']]

  loaded = false

  startLoading(){
    this.loaded = false
  }

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  randomTableId = ''
  schemeSelected = false
  button = []

  constructor() {
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.loadedContent = this.content
    
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();

      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
      this.loaded = true
    });
  }

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

  ngOnInit(): void {
    this.randomTableId = this.makeid(5)

    if(this.hasCallback){
      this.button = [ 
        {
          text: this.buttonText,
          action: () => {
            var selectedData = $("#"+this.randomTableId).dataTable().api().rows( { selected: true } ).data()
            this.onTableSelect.emit([selectedData]);
          }
        }
      ]
    }

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthChange:false,
      dom: 'Blfrtip',
      select:{style:'single'},
      buttons:this.button,
      initComplete: function () {
        $('.dt-button').addClass( "btn btn-primary" )
      }
   }
  }

}
