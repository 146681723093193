import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ClientDashComponent } from './client-dash/client-dash.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AdminPageComponent } from './admin-dash/admin-page/admin-page.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: 'client-dash', component:ClientDashComponent },
  { path: 'admin-dash', component: AdminPageComponent },
  { path: '',   redirectTo: '/login', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
