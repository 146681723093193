import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import Swal from 'sweetalert2';
import * as utils from './utils.js';
import { faEthernet, faUser, faMoneyBill, faChartLine, faBuilding, faBook, faUserCog, faLock, faTachometerAlt, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiAutoCompleateModel } from '../harpy.js';
import { regExpEscape } from '@ng-bootstrap/ng-bootstrap/util/util';
import { ApiResponse, AuthenticatedGateway } from 'src/app/common/alex.js';


@Component({
  selector: 'app-harpy-client-product',
  templateUrl: './harpy-client-product.component.html',
  styleUrls: ['./harpy-client-product.component.css']
})
export class HarpyClientProductComponent implements OnInit {

  PASSWORD_LENGTH = 8 

  @Input()  authenticatedGateway: AuthenticatedGateway;
  @ViewChild('productModal') productModal: any;
  @ViewChild('ownerChangeModal') ownerChangeModal: any;

  harpyLoading = false
  schemesApiAutocompleate = undefined
  productCatalogApiAutocompleate = undefined
  productIsBeingEdit = false
  clientIsBeingEdit = false

  lock = faLock
  conn = faEthernet
  user = faUser
  moneyCheck = faMoneyBill
  chartLine = faChartLine
  building = faBuilding
  speed = faTachometerAlt
  userCog = faUserCog
  calendar = faCalendar

  
  httpOptions = {
      headers: new HttpHeaders({ 
        'Access-Control-Allow-Origin':'*',
      })
  };

  clientForm = {
    cpfCnpj: '', 
    customerName: '',
    customerContact:{
        phone1: '',
        phone2: '',
        emailAddress: '',
    },
    customerAddress:{
        zip:'',
        city:'',
        state:'',
        street:'',
        number:'',
        neighborhood:'',
        complement:'',
    },
    birthdate: ''
  }

  ownerChangeForm = {
    cpfCnpjNewOwner:'',
    newOwnerObj:undefined
  }

  formLock = true
  locationFormLock = true

  products = []
  productOnModal = {}
  productToSend = {}

  queryProducts(){
    this.harpyLoading = true
    this.authenticatedGateway.authPost(
      'harpy/product/query',
      {owner:this.clientForm.cpfCnpj}
    ).then(
      (apiResponse) => {
        let d = apiResponse.payload
        if(d.details.length > 0){
          this.products = d.details
          this.products.forEach((e, i)=>{
            this.products[i]['frontProductAssignedPrice'] = String(this.products[i]['productAssignedPrice']).replace('.', ',')
            this.products[i]['productAssignedPrice'] = this.products[i]['productAssignedPrice']
            if(e.spixInsertionDate != null || e.spixInsertionDate != ''){
              var date = new Date(this.products[i]['spixInsertionDate']);
              this.products[i]['spixInsertionDate'] = date.toISOString().substr(0,10);
            }
          })
          this.harpyLoading = false
        }
      }
    )
  }

  queryObject(){
    this.harpyLoading = true
    this.authenticatedGateway.authPost(
      'harpy/customer/query',
      {cpfCnpj:this.clientForm.cpfCnpj}
    ).then(
      (apiResponse)=>{
        let d = apiResponse.payload
        if(d.details.length > 0){
          this.clientForm = d.details[0]
          this.queryProducts()
          this.formLock = false
          this.locationFormLock = false
          this.clientIsBeingEdit = true
        }
        else{
          Swal.fire({
            title:"Usuário não cadastrado ainda", 
            text:' Formulário desbloqueado', timer: 3500,
            showConfirmButton: false,
            toast: true,
            position: 'top-right'
          })
          this.formLock = false
          this.clientIsBeingEdit = false
        }
      }
    )
  }

  cpfCnpjKeyUp(){
    if(utils.verificaCpfCnpj(this.clientForm['cpfCnpj']) != false){
      if (utils.validaCpfCnpj(this.clientForm['cpfCnpj'])){
        this.queryObject()
      }
      else{
        this.clearForm()
        Swal.fire({
          title:"CPF/CNPJ inválido", 
          text:'', timer: 2000,
          showConfirmButton: false,
          toast: true,
          position: 'top-right'
        })
      }
    }
    else{
      this.clearForm()
    }
  }

  getZip(){
    this.authenticatedGateway.authGet(
      'harpy/getZipInfo/'+this.clientForm.customerAddress.zip.trim()
    ).then(
      (apiResponse) => {
        let d = apiResponse.payload
        if(d.status == true){
          if(d.contents.erro == undefined){
            this.clientForm.customerAddress = {
              zip:this.clientForm.customerAddress.zip.trim(),
              city:d.contents.localidade,
              state:d.contents.uf,
              street:d.contents.logradouro,
              number:this.clientForm.customerAddress.number,
              neighborhood:d.contents.bairro,
              complement:this.clientForm.customerAddress.complement,
            }
            this.locationFormLock = false
          }
          else{        
            this.locationFormLock = true
            Swal.fire({
              title:"CEP Inválido", 
              text:'verifique e tente novamente', timer: 3500,
              showConfirmButton: false,
              toast: true,
              position: 'top-right'
            })
          }
        }
      }
    )
  }

  zipKeyUp(){
    if(this.clientForm.customerAddress.zip.length == 8){
      this.getZip()
    }
    else{
      this.locationFormLock = true
    }
  }

  disableClient(){
    this.areYouSure("desativar o cliente", ()=>{
      Swal.fire({
        title: 'Desativando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading()
          return this.authenticatedGateway.authDelete(
            'harpy/customer/item/'+this.clientForm['_id'], {}
          ).then(
            (apiResponse) => {
              let d = apiResponse.payload
              Swal.close()
              if(d.status){
                this.clientForm['cpfCnpj'] = ''
                this.cpfCnpjKeyUp()
                this.clearForm()
                Swal.fire("Pronto", 'Tudo certo!', 'success').then(()=>{
                  this.modalService.dismissAll();
                })
              } 
              else{
                Swal.fire("Algo deu errado", d.details, 'error')
              }
            }
          ).then(
            (e) => {
              Swal.insertQueueStep({
                icon: 'error',
                title: 'Algo deu errado!'
              })
            }
          )
        }
      })
    })
  }

  isNumeric(str) {
    if (str.trim() === '') return false
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  nop(){
    return
  }

  validadeClientData(){
    // TODO: VALIDATE ZIP AGAIN
    var text = []
    var phoneRegex = /^[1-9]{2}9?[0-9]{8}$/
    if(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(this.clientForm.customerContact.emailAddress))
      this.nop()
    else
      text.push('Email')
    if(!this.isNumeric(this.clientForm.customerContact.phone1) || !(phoneRegex.test(this.clientForm.customerContact.phone1)))
      text.push('Telefone 1')
    if(!this.isNumeric(this.clientForm.customerContact.phone2) || !(phoneRegex.test(this.clientForm.customerContact.phone2)))
      text.push('Telefone 2')
    if(!this.isNumeric(this.clientForm.customerAddress.zip))
      text.push('CEP')
    if(!this.isNumeric(this.clientForm.customerAddress.number))
      text.push('Número')
    if(this.clientForm.customerAddress.city === '')
      text.push('Cidade')
    if(this.clientForm.customerAddress.state === '')
      text.push('Estado')
    if(this.clientForm.customerAddress.street === '')
      text.push('Rua')
    if(this.clientForm.customerAddress.complement === '')
      text.push('Complemento')
    if(this.clientForm.customerName === '')
      text.push('Nome')
    if(text.length == 1)
      Swal.fire('Problema na validação dos dados', 'verifique o campo: '+text[0], 'error')
    else if(text.length == 0)
      return true
    else
      Swal.fire('Problema na validação dos dados', 'verifique os campos: '+text.join(', '), 'error')
  }
  saveClient(){
    if(this.validadeClientData()){
      if(this.clientIsBeingEdit){
        var toSave = Object.assign({}, this.clientForm);
        delete(toSave['_id'])
        delete(toSave['__v'])
        Swal.fire({
          title: 'Salvando...',
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Swal.showLoading()
            return this.authenticatedGateway.authPatch(
              'harpy/customer/item/'+this.clientForm['_id'],
              toSave
            ).then(
              (apiResponse) => {
                let d = apiResponse.payload
                Swal.close()
                if(d.status){
                  this.cpfCnpjKeyUp()
                  Swal.fire("Pronto", 'Tudo certo!', 'success').then(()=>{
                  })
                } 
                else{
                  Swal.fire("Algo deu errado", d.details, 'error')
                }
              }
            ).catch(
              (e) => {
                Swal.insertQueueStep({
                  icon: 'error',
                  title: 'Algo deu errado!'
                })
              }
            )
          }
        })
      }
      else{
        var toSave = Object.assign({}, this.clientForm);
        Swal.fire({
          title: 'Salvando...',
          allowOutsideClick: false,
          onBeforeOpen: () => {
            Swal.showLoading()
            return this.authenticatedGateway.authPost(
              'harpy/customer',
              toSave
            ).then(
              (apiReponse) => {
                let d = apiReponse.payload
                Swal.close()
                if(d.status){
                  this.cpfCnpjKeyUp()
                  Swal.fire("Pronto", 'Tudo certo!', 'success').then(()=>{
                  })
                } 
                else{
                  Swal.fire("Algo deu errado", d.details, 'error')
                }
              }
            ).catch(
              (e) => {
                Swal.insertQueueStep({
                  icon: 'error',
                  title: 'Algo deu errado!'
                })
              }
            )
          }
        })
      }
    }
  }

  generatePassword(length) {
    var charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }
  generateUsrPwd(){
    var schemeName = this.productOnModal['scheme'].schemeName
    var clientComplement = this.clientForm.customerAddress.complement
    var name = schemeName.split(' ').map(e=>{return e.substring(0,2).toUpperCase()}).join('')+'_'+clientComplement.replace(/[^\d.-]/g, '');
    var password = this.generatePassword(this.PASSWORD_LENGTH)
    this.productOnModal['productInnerSchemeId'] = name 
    this.productOnModal['connectionId'] = name
    this.productOnModal['password'] = password
  }
  onchangeProductCatalog(option){
    var sel = this.productCatalogApiAutocompleate.findSelectedObj(option)
    if(sel != undefined){
      this.productOnModal['productName'] = sel.name
      this.productOnModal['productAssignedPrice'] = sel.suggestedPrice
      this.productOnModal['fromCatalog']['_id'] = sel._id
    }
  }

  onchangeScheme(option){
    var sel = this.schemesApiAutocompleate.findSelectedObj(option)
    //console.info(sel)
    if(sel != undefined){
      this.generateUsrPwd()
      this.productOnModal['scheme']['_id'] = sel._id
    }
  }

  newProduct(){
    if(!this.clientIsBeingEdit){
      Swal.fire({
        title:"Não podemos criar um produto ainda", 
        text:'Salve o cliente antes de fazer isso!', timer: 3500,
        showConfirmButton: false,
        toast: true,
        position: 'top-right'
      })
      return
    }
    this.productIsBeingEdit = false
    this.productOnModal ={ 
      connectionId: "",
      fromCatalog: {name: "", _id:''},
      owner: {_id: this.clientForm['_id']},
      password: "",
      productAssignedPrice: 0,
      productInnerSchemeId: "",
      productName: "",
      productPaymentDue: 10,
      scheme: {schemeName: "", _id:''},
      spixInsertionDate: ""
    }
    this.schemesApiAutocompleate = new ApiAutoCompleateModel(this.authenticatedGateway, 'schemeName', 'scheme')
    this.productCatalogApiAutocompleate = new ApiAutoCompleateModel(this.authenticatedGateway, 'name', 'productCatalog')
    this.open(this.productModal)
  }
  editProduct(idx){
    this.productIsBeingEdit = true
    this.productOnModal = Object.assign({}, this.products[idx])
    if(this.productOnModal['spixInsertionDate'] == undefined){
      this.productOnModal['spixInsertionDate'] = ""
    }
    this.schemesApiAutocompleate = new ApiAutoCompleateModel(this.authenticatedGateway, 'schemeName', 'scheme')
    this.productCatalogApiAutocompleate = new ApiAutoCompleateModel(this.authenticatedGateway, 'name', 'productCatalog')
    this.open(this.productModal)
  }
  deleteProduct(){
    this.areYouSure("excluir o produto", ()=>{
      Swal.fire({
        title: 'Excluindo...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading()
          return this.authenticatedGateway.authDelete(
            'harpy/product/item/'+this.productOnModal['_id'], {}
          ).then(
            (apiResponse) => {
              let d = apiResponse.payload
              Swal.close()
              if(d.status){
                Swal.fire("Pronto", 'Tudo certo!', 'success').then(()=>{
                  this.cpfCnpjKeyUp();
                  this.modalService.dismissAll();
                })
              } 
              else{
                Swal.fire("Algo deu errado", d.details, 'error')
              }
            }
          ).catch(
            (e) => {
              console.info(e)
              Swal.insertQueueStep({
                icon: 'error',
                title: 'Algo deu errado!'
              })
            }
          )
        }
      })
    })
    
  }

  saveEditProduct(){
    var toSave = Object.assign({}, this.productToSend);
    toSave['productAssignedPrice'] = toSave['productAssignedPrice']
    delete(toSave['frontProductAssignedPrice'])
    delete(toSave['_id'])
    delete(toSave['__v'])
    Swal.fire({
      title: 'Salvando...',
      allowOutsideClick: false,
      onBeforeOpen: () => {
        Swal.showLoading()
        return this.authenticatedGateway.authPatch(
          'harpy/product/item/'+this.productToSend['_id'],
          toSave
        ).then(
          (apiResponse) => {
            let d = apiResponse.payload
            Swal.close()
            if(d.status){
              this.cpfCnpjKeyUp()
              Swal.fire("Pronto", 'Tudo certo!', 'success').then(()=>{
                this.modalService.dismissAll();
              })
            } 
            else{
              Swal.fire("Algo deu errado", d.details, 'error')
            }
          }
        ).catch(
          (e)=>{
            console.info(e)
            Swal.insertQueueStep({
              icon: 'error',
              title: 'Algo deu errado!'
            })
          }
        )
      }
    })
  }
  newProductValidade():boolean{
    var text = []
    if(
      (this.productOnModal['productPaymentDue'] != 1)  &&
      (this.productOnModal['productPaymentDue'] != 10) &&
      (this.productOnModal['productPaymentDue'] != 20)
    )
      text.push('Dia do vencimento')
    if(this.productOnModal['connectionId'] == '')
      text.push('ID da conexão')
    if(this.productOnModal['password'] == '')
      text.push('Senha da Conexão')
    if(this.productOnModal['productInnerSchemeId'] == '')
      text.push('ID interno do scheme')
    if(text.length == 1)
      Swal.fire('Problema na validação dos dados', 'verifique o campo: '+text[0], 'error')
    else if(text.length == 0)
      return true
    else
      Swal.fire('Problema na validação dos dados', 'verifique os campos: '+text.join(', '), 'error')
  }
  saveNewProduct(){
      var toSave = Object.assign({}, this.productToSend);
      toSave['productAssignedPrice'] = toSave['productAssignedPrice']
      Swal.fire({
        title: 'Salvando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading()
          return this.authenticatedGateway.authPost(
            'harpy/product',
            toSave
          ).then(
            (apiResponse)=>{
              let d = apiResponse.error
              Swal.close()
              if(d.status){
                this.cpfCnpjKeyUp()
                Swal.fire("Pronto", 'Tudo certo!', 'success').then(()=>{
                  this.modalService.dismissAll();
                })
              } 
              else{
                Swal.fire("Algo deu errado", d.details, 'error')
              }
            }
          ).catch(
            (e)=>{
              console.info(e)
              Swal.insertQueueStep({
                icon: 'error',
                title: 'Algo deu errado!'
              })
            }
          )
        }
      })
    
  }

  translateToIdAndSaveProduct(){
    if(this.newProductValidade()){
      this.productToSend = Object.assign({}, this.productOnModal);
      const fromCatalogId = this.productToSend['fromCatalog']._id
      const ownerId = this.productToSend['owner']._id
      const schemeId = this.productToSend['scheme']._id
      this.productToSend['productAssignedPrice'] = Number(this.productToSend['productAssignedPrice']+''.replace(',', '.'))
      if((fromCatalogId == '') || (ownerId == '') || (schemeId == '')){
        Swal.fire('Opps', 'Parece que você não selecionou o scheme ou produto do catálogo', 'warning')
        return
      }
      else{
        this.productToSend['fromCatalog'] = fromCatalogId
        this.productToSend['owner'] = ownerId
        this.productToSend['scheme'] = schemeId
      }
      if(this.productIsBeingEdit)
        this.saveEditProduct()
      else
        this.saveNewProduct()
    }
    
  }

  schemeOnKeyUp(){
    this.schemesApiAutocompleate.searchTerm = this.productOnModal['scheme'].schemeName
    this.schemesApiAutocompleate.apiAutoCompleate()
  }

  productCatalogOnKeyUp(){
    this.productCatalogApiAutocompleate.searchTerm = this.productOnModal['fromCatalog'].name
    this.productCatalogApiAutocompleate.apiAutoCompleate()
  }

  // Start Owner Change Code 

  changeProductOwner(){
    // Open Owner Change Form
    this.open(this.ownerChangeModal)
  }

  queryCpfCnpjOwnerChange(){
    // Query Harpy for an owner 
    if(this.ownerChangeForm.cpfCnpjNewOwner == this.clientForm.cpfCnpj){
      Swal.fire({
        title:"Não é possivel transferir produto para o mesmo usuário", 
        text:'Verifique o CPF/CNPJ de destino', 
        showConfirmButton: true
      })
    }
    else{
      this.harpyLoading = true
      this.authenticatedGateway.authPost(
        'harpy/customer/query', 
        {cpfCnpj:this.ownerChangeForm.cpfCnpjNewOwner}
      ).then(
        (apiResponse) => {
          let d = apiResponse.payload
          if(d.details.length > 0)
          this.ownerChangeForm.newOwnerObj = d.details[0]
        else{
          this.modalService.dismissAll()
          Swal.fire({
            title:"Usuário não cadastrado ainda", 
            text:'Realize o cadastro do novo titular antes de realizar a troca de titularidade', 
            showConfirmButton: true
          })
        }
        }
      )
    }
    
  }

  clearOwnerChangeForm(){
    this.ownerChangeForm.cpfCnpjNewOwner = ''
    this.ownerChangeForm.newOwnerObj = undefined
  }

  ownerCpfCnpjKeyUp(){
      if(utils.verificaCpfCnpj(this.ownerChangeForm.cpfCnpjNewOwner) != false){
        if (utils.validaCpfCnpj(this.ownerChangeForm.cpfCnpjNewOwner)){
          this.queryCpfCnpjOwnerChange()
        }
        else{
          this.clearOwnerChangeForm()
          Swal.fire({
            title:"CPF/CNPJ inválido", 
            text:'', timer: 2000,
            showConfirmButton: false,
            toast: true,
            position: 'top-right'
          })
        }
      }
  }

  makeTransfer(){
    if(this.ownerChangeForm.newOwnerObj == undefined || this.ownerChangeForm.newOwnerObj['_id'].trim() == ''){
      Swal.fire({
        title:"Novo titular não informado...", 
        text:'', timer: 2000,
        showConfirmButton: false,
        toast: true,
        position: 'top-right'
      })
    }
    else if (this.productOnModal['_id'] === undefined || this.productOnModal['_id'].trim() == ''){
      Swal.fire({
        title:"Titular atual náo informado...", 
        text:'', timer: 2000,
        showConfirmButton: false,
        toast: true,
        position: 'top-right'
      })
    }
    else{
      this.areYouSure('realizar a transferência de titularidade', ()=>{
        let transferObject = {
          "fromProduct":this.productOnModal['_id'],
          "newOwner":this.ownerChangeForm.newOwnerObj['_id'],
          "status":"new"
      }
      this.authenticatedGateway.authPost(
        'harpy/ownershipChange',
        transferObject
      ).then(
        (apiResponse) => {
          let d = apiResponse.payload
          if(d.status){
            Swal.fire({
              icon:'success',
              title:"Pronto!", 
              html:'A troca de titularidade, foi adicionada a fila execução, dentro de alguns minutos os dados irão refletir no sistema.<br>Não esqueça de informar o cliente que a internet dele pode oscilar nas proximas horas.', 
              showConfirmButton: true
            })
            this.clearOwnerChangeForm()
            this.modalService.dismissAll()            
          }
          else{
            Swal.fire({
              icon:'error',
              title:"Ops!", 
              html:'Algo deu errado! Entre em contato com o administrador<br>'+d.details, 
              showConfirmButton: true
            })
          }
        }
      )
      })
    }
  }

  clearForm(){
    this.products = []
    this.clientIsBeingEdit = false
    this.clientForm = {
      cpfCnpj: this.clientForm.cpfCnpj, 
      customerName: '',
      customerContact:{
          phone1: '',
          phone2: '',
          emailAddress: '',
      },
      customerAddress:{
          zip:'',
          city:'',
          state:'',
          street:'',
          number:'',
          neighborhood:'',
          complement:'',
      },
      birthdate: '',
    }
    this.formLock = true
    this.locationFormLock = true
  }

  constructor(private http:HttpClient, private modalService: NgbModal) { }

  closeResult = '';

  open(content) {
    this.modalService.open(content, {size: 'lg', centered:true, ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit(): void {
    this.clearForm()
  }

  areYouSure(actionText, callback){
    Swal.fire({
        title: 'Você tem certeza que deseja '+actionText+'?',
        text: "Essa ação não poderá ser revertida!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, eu tenho certeza!',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.isConfirmed)
          callback()
      })
  }

}
