import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { spixConnector } from '../spix_views/spix-view/spix-connector';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import {
  ChartComponent,
  ApexDataLabels,
  ApexLegend,
  ApexPlotOptions,
  ApexChart,
  ApexTheme,
  ApexResponsive 
} from "ng-apexcharts";
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

export type ChartOptions = {
  series: Array<Number>;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: Array<String>;
  theme: ApexTheme;
  legend: ApexLegend;
  options: ApexPlotOptions;
  dataLabels: ApexDataLabels;
};

import { AuthenticatedGateway } from '../../common/alex';

@Component({
  selector: 'app-indicadores',
  templateUrl: './indicadores.component.html',
  styleUrls: ['./indicadores.component.css']
})
export class IndicadoresComponent implements OnInit {
  @Input()  authenticatedGateway: AuthenticatedGateway;
  connector: spixConnector = undefined
  public spixReportsLoaded = false
  public schemeReportsLoaded = false
  public eventsReportsLoaded = false
  
  public subsLoaded = false
  public showSubTableCard = false
  public spixViewCloseControl = false
  public spixViewHasCloseControl = false

  httpOptions = {
    headers: new HttpHeaders({ 
      'Access-Control-Allow-Origin':'*',
    })
  };

  // Finantial Reports

  @ViewChild('viewSubsModal') spixModal: any;
  
  selectedSubs = []
  reports = {
    'active':{count:0, details:undefined, type:'subscription'},
    'defaulted':{count:0, details:undefined, type:'subscription'},
    'blocked':{count:0, details:undefined, type:'subscription'},
    'confirmed_payment':{count:0, details:undefined, type:'payment'},
    'waiting_payment':{count:0, details:undefined, type:'payment'},
    'payment_defaulted':{count:0, details:undefined, type:'payment'},
  }

  // System Report Charts
  //@ViewChild("eventChart") eventChart: ChartComponent;
  @ViewChild("schemeChart") schemeChart: ChartComponent;
  public eventChartOptions: Partial<ChartOptions>;
  public schemeChartOptions: Partial<ChartOptions>;

  eventDispatchRegister = {
    "productStateChange":{'statusVar':'status', 'frontName':'Produtos'},
    "notifications":{'statusVar':'notificationStatus', 'frontName':'Notificações'},
    "smsDispatch":{'statusVar':'dispatchState', 'frontName':'Envio de SMS'},
    "ownershipChange":{'statusVar':'status', 'frontName':'Troca de Titularidade'}
  }

  eventInQueueCount = 0

  public createDounutChartOptions(width, height, breakpoint): Partial<ChartOptions>{
    return {
      series: [],
      chart: {
        width: "100%",
        type: "donut"
      },
      labels: [
      ],
      theme: {
        monochrome: {
          enabled: true
        }
      },
      legend: {
        position: 'bottom',
      },
      responsive: [
        {
          breakpoint: breakpoint,
          options: {
            chart: {
              width: width,
              height: height
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ],
      dataLabels:{
        enabled: false,
      },
      options: {
        pie: {
          startAngle: 0,
          endAngle: 360,
          expandOnClick: false,
          offsetX: 0,
          offsetY: 0,
          customScale: 1,
          dataLabels: {
              offset: 0,
              minAngleToShowLabel: 10
          }, 
          donut: {
            size: '65%',
            background: 'transparent',
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: '22px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: undefined,
                offsetY: -10,
                formatter: function (val) {
                  return val
                }
              },
              value: {
                show: true,
                fontSize: '16px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 400,
                color: undefined,
                offsetY: 16,
                formatter: function (val) {
                  return val
                }
              },
              total: {
                show: true,
                showAlways: true,
                label: 'Total',
                fontSize: '22px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 600,
                color: '#373d3f',
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => {
                    return a + b
                  }, 0)
                }
              }
            }
          },      
        }
      }
      
    };
  }

  public async loadEventChart(){
    for(let eventDispatchQueue of Object.keys(this.eventDispatchRegister)){
      let varBackend = this.eventDispatchRegister[eventDispatchQueue].statusVar
      let frontName = this.eventDispatchRegister[eventDispatchQueue].frontName
      
      let queryObj = {}
      queryObj[varBackend] = 'new'

      //let response = await this.http.post<any>(
      //  'api/harpy/'+eventDispatchQueue+'/query?token='+this.token, 
      //  queryObj, this.httpOptions
      //).toPromise()
      let response_api = await this.authenticatedGateway.authPost('harpy/'+eventDispatchQueue+'/query', queryObj)
      let response:any = response_api.payload;
      if(response.status){
        this.eventChartOptions.series.push(response.details.length)
        this.eventChartOptions.labels.push(frontName)
      }        
    }
  }

  public async loadSchemeChart(){
    let response_api = await this.authenticatedGateway.authGet('harpy/schemeStatus')
    let response:any = response_api.payload;

    let onlineSchemes = 0 
    let offlineSchemes = 0
    response['details'].forEach(element => {
      if(element.isAlive)
        onlineSchemes += 1
      else
        offlineSchemes += 1
    });
    this.schemeChartOptions.labels = ['Online', 'Offline']
    this.schemeChartOptions.series = [onlineSchemes, offlineSchemes]
  }

  constructor(
    private http:HttpClient,
    private modalService: NgbModal
  ){
    
    this.eventChartOptions = this.createDounutChartOptions(150, 100, 400)
    this.schemeChartOptions = this.createDounutChartOptions(150, 100, 400)

  }
  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }
  ngOnInit(): void {
    this.connector = new spixConnector(this.authenticatedGateway)

    this.loadSchemeChart().then(()=>{this.schemeReportsLoaded  = true})
    this.loadEventChart().then(()=>{this.eventsReportsLoaded = true})

    this.connector.getNumbers().then(e=>{
        this.reports['active'].count = e['sub']['active'].length
        this.reports['active'].details = e['sub']['active']

        this.reports['defaulted'].count = e['sub']['defaulted'].length
        this.reports['defaulted'].details = e['sub']['defaulted']

        this.reports['blocked'].count = e['sub']['blocked'].length
        this.reports['blocked'].details = e['sub']['blocked']

        this.reports['confirmed_payment'].count = e['pays']['paid'].length
        this.reports['confirmed_payment'].details = e['pays']['paid']

        this.reports['waiting_payment'].count = e['pays']['waitng'].length
        this.reports['waiting_payment'].details = e['pays']['waitng']

        this.reports['payment_defaulted'].count = e['pays']['defaulted'].length
        this.reports['payment_defaulted'].details = e['pays']['defaulted']

        this.spixReportsLoaded = true
    })

    this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        lengthChange:false,
        dom: 'Blfrtip',
        select:{style:'single'},
        buttons:[ 
          {
            text: 'Detalhes',
            action: () => {
              var selectedData = $("#selectedSubFilterTable").dataTable().api().rows( 
                { selected: true } 
              ).data()
              if (selectedData[0] != undefined){
                this.connector.singleSearchForm.searchOption = 'idProduto'
                this.connector.singleSearchForm.searchText = selectedData[0][0]
                this.connector.singleSearch().then(e =>{
                  if(e['status'] == false){
                    
                  }
                  else{
                    this.open(this.spixModal)
                    this.spixViewCloseControl = true
                  }
                })
              }
            }
          }
        ],
        initComplete: function () {
          $('.dt-button').addClass( "btn btn-primary" )
        }
    }
  }

  calculateTotal(identifier){
    var pays = this.reports[identifier].details
    var runningTotal = 0
    for(let pay of pays){
      runningTotal += pay['payment_amount']
    }
    return runningTotal.toFixed(2).replace('.',',')
  }


  removeDuplicates(arr, equals) {
      var originalArr = arr.slice(0);
      var i, len, val;
      arr.length = 0;

      for (i = 0, len = originalArr.length; i < len; ++i) {
          val = originalArr[i];
          if (!arr.some(function(item) { return equals(item, val); })) {
              arr.push(val);
          }
      }
  }

  thingsEqual(thing1, thing2) {
      return thing1.place === thing2.place
          && thing1.name === thing2.name;
  }

  closeResult = '';

  open(content) {
    this.modalService.open(content, {windowClass : 'larger-modal', centered:true, ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  clicked (identifier){
    this.showSubTableCard = true
    this.subsLoaded = false

    if(this.reports[identifier].type == "payment"){
      var filtered = []
      for(let pay of this.reports[identifier].details){
        filtered.push(pay['fromSubscription'])
      }
      this.removeDuplicates(filtered, this.thingsEqual)
      this.selectedSubs = filtered
    }
    else{
      this.selectedSubs = this.reports[identifier].details
    }
    this.rerender()
  }

  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
      setTimeout(()=>{ 
        this.subsLoaded = true
      }, 800);  
    });
  }

}
