import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { ApiAutoCompleateModel } from '../harpy';
import Swal from 'sweetalert2';
import { AuthenticatedGateway } from 'src/app/common/alex';


@Component({
  selector: 'app-harpy-catalog',
  templateUrl: './harpy-catalog.component.html',
  styleUrls: ['./harpy-catalog.component.css']
})
export class HarpyCatalogComponent implements OnInit {

  @Input()  authenticatedGateway: AuthenticatedGateway;
  productCatalogApi = undefined
  formLock = true
  catalogIsBeingEdit = false
  form = {
    name:"",
    rateLimit:"",
    schemeIdentifier:"",
    suggestedPrice:"",
  }
  httpOptions = {
    headers: new HttpHeaders({ 
      'Access-Control-Allow-Origin':'*',
    })
  };

  saveForm(){
    if(this.catalogIsBeingEdit){
      var toSave = Object.assign({}, this.form);
      delete(toSave['_id'])
      delete(toSave['__v'])
      Swal.fire({
        title: 'Salvando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading()
          return this.authenticatedGateway.authPatch(
            'harpy/productCatalog/item/'+this.form['_id'],
            toSave
          ).then(
            (apiResponse) => {
              let d = apiResponse.payload
              Swal.close()
              if(d.status){
                this.productCatalogApi = new ApiAutoCompleateModel(this.authenticatedGateway, 'name', 'productCatalog')
                this.clearForm()
                Swal.fire("Pronto", 'Tudo certo!', 'success').then(()=>{})
              } 
              else{
                Swal.fire("Algo deu errado", d.details, 'error')
              }
            }
          ).catch(
            (e)=>{
              console.info(e)
              Swal.insertQueueStep({
                icon: 'error',
                title: 'Algo deu errado!'
              })
            }
          )
        }
      })
    }
    else{
      var toSave = Object.assign({}, this.form);
      Swal.fire({
        title: 'Salvando...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading()
          return this.authenticatedGateway.authPost(
            'harpy/productCatalog',
            toSave
          ).then(
            (apiResponse) => {
              let d = apiResponse.payload
              Swal.close()
              if(d.status){
                this.productCatalogApi = new ApiAutoCompleateModel(undefined, 'name', 'productCatalog')
                this.clearForm()
                Swal.fire("Pronto", 'Tudo certo!', 'success').then(()=>{
                })
              } 
              else{
                Swal.fire("Algo deu errado", d.details, 'error')
              }
            }
          ).catch(
            (e)=>{
              console.info(e)
              Swal.insertQueueStep({
                icon: 'error',
                title: 'Algo deu errado!'
              })
            }
          )
        }
      })
    }
  }

  newItem(){
    this.clearForm()
    this.formLock = false
  }

  constructor(private http:HttpClient) { 
    
  }

  editItem(object){
    this.clearForm();
    this.form = Object.assign({}, object);
    
    this.formLock = false
    this.catalogIsBeingEdit = true
  }

  clearForm(){
    this.form = {
      name:"",
      rateLimit:"",
      schemeIdentifier:"",
      suggestedPrice:"",
    }
    this.formLock = true
    this.catalogIsBeingEdit = false
  }

  searchChange(){
    this.productCatalogApi.apiAutoCompleate()
  }

  ngOnInit(): void {
    this.productCatalogApi = new ApiAutoCompleateModel(this.authenticatedGateway, 'name', 'productCatalog')
  }

}
