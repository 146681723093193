<h4>Sistema FiberFast:</h4>
<div class="row">
  <div class="col-md-6">
    <div class="card" >
      <div class="card-body">
        <h5 class="card-title">Status Condominios</h5>
        <div class="loader" *ngIf="!schemeReportsLoaded"></div>
          <div *ngIf="schemeReportsLoaded" >
            <div class="chartContainer" id="schemeChart">
              <apx-chart
                [series]="schemeChartOptions.series"
                [chart]="schemeChartOptions.chart"
                [labels]="schemeChartOptions.labels"
                [theme]="schemeChartOptions.theme"
                [responsive]="schemeChartOptions.responsive"
                [legend]="schemeChartOptions.legend"
                [plotOptions]="schemeChartOptions.options"
                [dataLabels]="eventChartOptions.dataLabels"
              ></apx-chart>
            </div>
          </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="card" >
      <div class="card-body">
        <h5 class="card-title">Eventos na Fila</h5>
        <div class="loader" *ngIf="!eventsReportsLoaded"></div>
          <div *ngIf="eventsReportsLoaded" >
            <div class="chartContainer" id="eventChart">
              <apx-chart
                [series]="eventChartOptions.series"
                [chart]="eventChartOptions.chart"
                [labels]="eventChartOptions.labels"
                [theme]="eventChartOptions.theme"
                [responsive]="eventChartOptions.responsive"
                [legend]="eventChartOptions.legend"
                [plotOptions]="eventChartOptions.options"
                [dataLabels]="eventChartOptions.dataLabels"
              ></apx-chart>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
<hr>
<h4>Financeiro:</h4>
<div class="row mt-2">
    <div class="col-md-4">
        <div class="card" >
            <div class="card-body">
              <h5 class="card-title">Assinaturas em Dia</h5>
              <div class="loader" *ngIf="!spixReportsLoaded"></div>
                <div class="title-safe card-text text-center mouseClick"  *ngIf="spixReportsLoaded" (click)="clicked('active')">
                      <h1>{{ reports.active.count }}</h1>
                </div>
            </div>
          </div>
    </div>
    <div class="col-md-4">
        <div class="card" >
            <div class="card-body">
              <h5 class="card-title">Assinaturas Inadimplentes</h5>
              <div class="loader" *ngIf="!spixReportsLoaded"></div>
                <div class="title-warn card-text text-center mouseClick"  *ngIf="spixReportsLoaded" (click)="clicked('defaulted')">
                    <h1>{{ reports.defaulted.count }}</h1>
                </div>
            </div>
          </div>
        
    </div>
    <div class="col-md-4">
      <div class="card" >
          <div class="card-body">
            <h5 class="card-title">Assinaturas Bloquadas</h5>
            <div class="loader" *ngIf="!spixReportsLoaded"></div>
              <div class="title-danger card-text text-center mouseClick"  *ngIf="spixReportsLoaded" (click)="clicked('blocked')">
                  <h1>{{ reports.blocked.count }}</h1>
              </div>
          </div>
        </div>
      
  </div>
</div>
<div class="row mt-2">
    <div class="col-md-4">
        <div class="card" >
            <div class="card-body">
              <h5 class="card-title">Pagamentos em Dia</h5>
              <div class="loader" *ngIf="!spixReportsLoaded"></div>
                <div class="title-safe card-text text-center mouseClick" *ngIf="spixReportsLoaded" (click)="clicked('confirmed_payment')">
                  <h1>R$ {{ calculateTotal('confirmed_payment') }}</h1>
                  <h3>{{ reports.confirmed_payment.count }}</h3>
                </div>
            </div>
          </div>
    </div>
    <div class="col-md-4">
        <div class="card" >
            <div class="card-body">
              <h5 class="card-title">Pagamentos em Aguardo</h5>
              <div class="loader" *ngIf="!spixReportsLoaded"></div>
  
              <div class="title-neutral card-text text-center mouseClick"  *ngIf="spixReportsLoaded" (click)="clicked('waiting_payment')"> 
                  <h1>R$ {{ calculateTotal('waiting_payment') }}</h1>
                  <h3>{{ reports.waiting_payment.count }}</h3>
                </div>
            </div>
          </div>
    </div>
    <div class="col-md-4">
      <div class="card" >
          <div class="card-body">
            <h5 class="card-title">Pagamentos em Atraso</h5>
            <div class="loader" *ngIf="!spixReportsLoaded"></div>
              <div class="title-warn card-text text-center mouseClick"  *ngIf="spixReportsLoaded" (click)="clicked('payment_defaulted')">
                <h1>R$ {{ calculateTotal('payment_defaulted') }}</h1>
                <h3>{{ reports.payment_defaulted.count }}</h3>
              </div>
          </div>
        </div>
  </div>
</div>

<div class="row mt-2" [hidden]="!showSubTableCard">
  <div class="col-md-12">
    <div class="card" >
        <div class="card-body">
          <h5 class="card-title">Assinaturas</h5>

          <div class="loading_wrap" [hidden]="subsLoaded">
            <div class="pulse"></div>
          </div>
          <div [hidden]="!subsLoaded">
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover" id="selectedSubFilterTable" >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Nome do Titular</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sub of selectedSubs">
                  <td>{{ sub.plan_id }}</td>
                  <td>{{ sub.name }}</td>
                  <td>
                      <b *ngIf="sub.status==0" class="title-safe">Ativo</b>
                      <b *ngIf="sub.status==1" class="title-warn">Inadimplente</b>
                      <b *ngIf="sub.status==2" class="title-warn">Cancelado</b>
                      <b *ngIf="sub.status==3" class="title-danger">Bloqueado</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>
      </div>
  </div>
</div>

<ng-template #viewSubsModal let-modal>

  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Visualizar Spix</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body">
    <div class="row" [hidden]="!spixViewCloseControl">
      <div class="col-md-12">
        <app-spix-view [(token)]="token" [(connector)]="connector" (closeControl)="spixViewCloseControl = !spixViewCloseControl" [(hasCloseControl)]="spixViewHasCloseControl"></app-spix-view>
      </div>
    </div>
  </div>
</ng-template>
