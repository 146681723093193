<nav *ngIf="exitCallback != undefined" class="navbar navbar-expand-lg navbar-light fixed-top py-3 navbar-scrolled" id="mainNav">
    <div class="container">
        <a class="navbar-brand js-scroll-trigger" href="#page-top">
            <img class="img-brand unselectable" src="assets/img/Logo-Vector_C_H.png" id="logo_img">
        </a>
        <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse"
            data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
            aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
        <div class="collapse navbar-collapse" id="navbarResponsive">
            <ul class="navbar-nav ml-auto my-2">
                <li class="nav-item"><a class="nav-link js-scroll-trigger unselectable" (click)="exitCallback()">Sair</a></li>
            </ul>
        </div>
    </div>
</nav>
<div *ngIf="exitCallback != undefined"class="page-section"></div>
<section>
    <div class="container">
        <h3 class="title unselectable" style="text-transform: capitalize;">Olá {{userData.clientName.split(' ')[0]}}</h3>
        <nav>
            <div class="nav nav-tabs unselectable" id="nav-tab" role="tablist">
                <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab"
                    aria-controls="nav-home" aria-selected="true">
                    <fa-icon [icon]="mousePointer" aria-hidden="true"></fa-icon>
                    Meus Planos
                </a>
                <a class="nav-item nav-link unselectable" id="nav-profile-tab" data-toggle="tab" href="#nav-profile"
                    role="tab" aria-controls="nav-profile" aria-selected="false">
                    <fa-icon [icon]="addressCard" aria-hidden="true"></fa-icon>
                    Informações Cadastrais
                </a>
                <!--a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">
                <i class="fa fa-comments mr-2" aria-hidden="true"></i>   
                Historico de Chamados
              </a-->
            </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                <div class="row mt-3">
                    <div class="col-md-12">
                        <div class="card shadow border-0">
                            <div class="card-header">
                                <div class="row text-black">
                                    <div class="col-md-1 text-right unselectable"><fa-icon [icon]="linkIcon"
                                            aria-hidden="true" size="3x"></fa-icon></div>
                                    <div class="col-md-11 text-left unselectable">
                                        <h4 class="mt-3">Meus Planos</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row p-3" *ngFor="let product of userData.products">
                    <div class="col-md-12">
                        <div class="card bg-light border-0 shadow-sm">
                            <div class="card-header text-black unselectable">
                                {{product.productName}}
                            </div>

                            <div class="card-body">
                                <nav *ngIf="product.subs">
                                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                        <a class="nav-item nav-link active" id="nav-prod-info-tab" data-toggle="tab"
                                            [href]='"#nav-prod-info-"+product.connectionId' role="tab"
                                            aria-selected="true">Informações</a>
                                        <a class="nav-item nav-link" id="nav-prod-bole-tab" data-toggle="tab"
                                            [href]='"#nav-prod-bole-"+product.connectionId' role="tab"
                                            aria-selected="false" (click)="accessedPaymentsOnce=true">
                                            Pagamentos
                                            <span
                                                *ngIf="(product.subs.payments.open.length > 0) && !accessedPaymentsOnce"
                                                class="badge badge-pill badge-primary"
                                                style="float:right;margin-left:5px;">{{product.subs.payments.open.length}}</span>
                                        </a>
                                    </div>
                                </nav>
                                <div class="tab-content" id="nav-tabContent">
                                    <div class="tab-pane fade show active p-2" [id]='"nav-prod-info-"+product.connectionId' role="tabpanel">
                                        <div class="row">
                                            <div class="col-md-12 unselectable">
                                                <h4>Informações Gerais</h4>
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-md-4 unselectable">
                                                <h5>Valor do Contrato: </h5>
                                            </div>
                                            <div class="col-md-2">
                                                R$ {{product.productAssignedPrice.toFixed(2).toString().replace('.',
                                                ',')}}
                                            </div>
                                            <div class="col-md-4 unselectable">
                                                <h5>Dia do Vencimento: </h5>
                                            </div>
                                            <div class="col-md-2">
                                                {{product.productPaymentDue}}
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-md-4 unselectable">
                                                <h5>ID do Plano: </h5>
                                            </div>
                                            <div class="col-md-2">
                                                {{product.productInnerSchemeId}}
                                            </div>
                                            <div class="col-md-4 unselectable">
                                                <h5>Senha do PPPoE: </h5>
                                            </div>
                                            <div class="col-md-2">
                                                {{product.password}}
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-md-4 unselectable">
                                                <h5>Status do Contrato: </h5>
                                            </div>
                                            <div class="col-md-8 link-up" *ngIf="(product.subs.status_text == 'active')"
                                                data-toggle="tooltip" data-placement="bottom"
                                                title="Você parece estar offline, verifique seu cabo e a configuração do seu equipamento...">
                                                <em class="unselectable">Ativo</em>
                                            </div>
                                            <div class="col-md-8 link-down"
                                                *ngIf="(product.subs.status_text == 'blocked')" data-toggle="tooltip"
                                                data-placement="bottom"
                                                title="Você parece estar offline, verifique seu cabo e a configuração do seu equipamento...">
                                                <em class="unselectable">Bloqueado por falta de pagamento</em>
                                                <p class="unselectable">
                                                    Seu plano foi bloqueado apos 45 dias sem pagamento.
                                                </p>
                                            </div>
                                            <div class="col-md-8 link-susp"
                                                *ngIf="(product.subs.status_text == 'defaulted')" data-toggle="tooltip"
                                                data-placement="bottom"
                                                title="Você parece estar offline, verifique seu cabo e a configuração do seu equipamento...">
                                                <em class="unselectable">Velocidade reduzida por falta de pagamento</em>
                                                <p class="unselectable">
                                                    Seu plano foi suspenso apos 15 dias sem pagamento.
                                                </p>
                                            </div>
                                        </div>
                                        <hr class="unselectable">
                                        <div class="row">
                                            <div class="col-md-12 unselectable">
                                                <h4>Saude do seu Sinal</h4>
                                            </div>
                                        </div>
                                        <div class="row mt-2">
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-md-4 unselectable">
                                                        <h5>Na sua região:</h5>
                                                    </div>
                                                    <div class="col-md-8 link-up"
                                                        *ngIf="(product.schemeStatus || product.productStatusAlive);  else elseBlock"
                                                        data-toggle="tooltip" data-placement="bottom"
                                                        title="Tudo parece estar ok até o seu roteador!">
                                                        <fa-icon [icon]="checkCircle" aria-hidden="true"></fa-icon>
                                                        <em class="ml-1 unselectable">
                                                            Online
                                                        </em>
                                                        <p class="ml-1 unselectable">
                                                            Sem falhas
                                                        </p>
                                                    </div>
                                                    <ng-template #elseBlock>
                                                        <div class="col-md-8 link-down" data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title="Parece que é nosso problema, provavelmente já estamos tratando deste problema, se preferir, abra um chamado">
                                                            <fa-icon [icon]="exclamationCircle"
                                                                aria-hidden="true"></fa-icon>
                                                            <em class="ml-1 unselectable">Offline</em>
                                                            <p class="ml-1 unselectable">
                                                                Parece que é nosso problema e já estamos atuando para
                                                                corrigir, se preferir, abra um chamado
                                                            </p>
                                                        </div>
                                                    </ng-template>
                                                </div>
                                            </div>

                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-md-4 unselectable">
                                                        <h5>Em seu equipamento:</h5>
                                                    </div>

                                                    <div class="col-md-8 link-up"
                                                        *ngIf="(product.productStatusActive && product.productStatusAlive)"
                                                        data-toggle="tooltip" data-placement="bottom" title="Tudo ok!">
                                                        <fa-icon [icon]="checkCircle" aria-hidden="true"></fa-icon>
                                                        <em class="ml-1 unselectable">Online</em>
                                                        <p class="ml-1 unselectable">
                                                            Sem falhas
                                                        </p>
                                                    </div>
                                                    <div class="col-md-8 link-down"
                                                        *ngIf="(!product.productStatusAlive && product.productStatusActive && product.schemeStatus)"
                                                        data-toggle="tooltip" data-placement="bottom"
                                                        title="Você parece estar offline, verifique seu cabo e a configuração do seu equipamento...">
                                                        <fa-icon [icon]="exclamationCircle"
                                                            aria-hidden="true"></fa-icon>
                                                        <em class="ml-1 unselectable">Offline</em>
                                                        <p class="ml-1 unselectable">
                                                            Você parece estar offline, verifique seu cabo e
                                                            certifique-se que seu roteador está ligado
                                                        </p>
                                                    </div>
                                                    <div class="col-md-8 link-down"
                                                        *ngIf="(!product.productStatusAlive && product.productStatusActive && !product.schemeStatus)"
                                                        data-toggle="tooltip" data-placement="bottom"
                                                        title="Você parece estar offline, verifique seu cabo e a configuração do seu equipamento...">
                                                        <fa-icon [icon]="exclamationCircle"
                                                            aria-hidden="true"></fa-icon>
                                                        <em class="ml-1 unselectable">Offline</em>
                                                    </div>
                                                    <div class="col-md-8 link-susp"
                                                        *ngIf="(!product.productStatusActive)" data-toggle="tooltip"
                                                        data-placement="bottom"
                                                        title="Seu plano está suspenso, você deve negociar suas dividas em aberto!">
                                                        <fa-icon [icon]="exclamationCircle"
                                                            aria-hidden="true"></fa-icon>
                                                        <em class="ml-1 unselectable">Velocidade Reduzida</em>
                                                        <p class="ml-1 unselectable">
                                                            Desculpe, tivemos que interromper o seu plano, pague suas
                                                            contas em atraso para liberar o seu sinal.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>
                                    <div class="tab-pane fade" [id]='"nav-prod-bole-"+product.connectionId' role="tabpanel">
                                        <br>
                                        <ul class="nav nav-pills" id="myTab" role="tablist">
                                            <li class="nav-item">
                                                <a class="nav-link active" id="open-tab" data-toggle="tab" [href]='"#open_"+product.connectionId'
                                                    role="tab" aria-controls="Abertos" aria-selected="true">Aberto</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" id="paid-tab" data-toggle="tab" [href]='"#paid_"+product.connectionId'
                                                    role="tab" aria-controls="Pagos" aria-selected="false">Pagos</a>
                                            </li>
                                        </ul>
                                        <div class="tab-content" id="myTabContent" *ngIf="product.subs">
                                            <div class="tab-pane fade show active" [id]='"open_"+product.connectionId' role="tabpanel">
                                                <div style="max-height: 512px; overflow-y: scroll; overflow-x: scroll;">

                                                    <div class="card mt-3"
                                                        *ngFor="let payment of product.subs.payments.open.concat(product.subs.payments.defaulted)">
                                                        <div class="card-header">
                                                            <b class="text-black">Cobrança #{{payment.id}} - </b>
                                                            <b *ngIf="payment.status == 0" class="title-warn">Aguardando
                                                                Pagamento</b>
                                                            <b *ngIf="payment.status == 1" class="title-warn">Aguardando
                                                                Pagamento</b>
                                                            <b *ngIf="payment.status == 2" class="title-safe">Paga</b>
                                                            <b *ngIf="payment.status == 3"
                                                                class="title-danger">Inadimplente</b>
                                                            <b *ngIf="payment.status == 4"
                                                                class="title-danger">Cancelada</b>
                                                        </div>
                                                        <div class="card-body">
                                                            <div class="row">
                                                                <div class="col-md-6"><b>Valor: </b>{{
                                                                    payment.payment_amount | currency:'BRL':true:'1.2-2'
                                                                    }}</div>
                                                                <div class="col-md-6"><b>Data de Vencimento: </b> {{
                                                                    payment.expire_date | date:'dd/LL/yyyy' : 'GMT' }}
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <b>Descrição: </b>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <p style="white-space:pre-wrap;">
                                                                        {{payment.payment_text}}</p>
                                                                </div>
                                                            </div>
                                                            <hr>
                                                            <div class="row" *ngIf="payment.details.bankSuccess">
                                                                <div class="col-md-6"><b>Código de Barras: </b></div>
                                                            </div>
                                                            <div class="row" *ngIf="payment.details.bankSuccess">
                                                                <div class="col-md-12">{{
                                                                    payment.details.contents.banking_billet.barcode }}
                                                                </div>
                                                                <div class="col-md-6 col-sm-12 mt-2">
                                                                    <button type="button"
                                                                        (click)="copyMessage(payment.details.contents.banking_billet.barcode)"
                                                                        class="btn btn-info"><fa-icon [icon]="clipboard"
                                                                            aria-hidden="true"></fa-icon> Copiar Código
                                                                        de Barras</button>
                                                                </div>
                                                            </div>
                                                            <div class="row" *ngIf="!payment.details.bankSuccess">
                                                                <div class="col-md-6"><b>Parece que o nosso parceiro
                                                                        bancario teve algum problema, tente mais tarde,
                                                                        se o problema persistir contate nosso time.</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-footer">
                                                            <div class="row">

                                                                <div class="col-md-2 col-sm-12 mt-2"
                                                                    *ngIf="payment.details.bankSuccess">
                                                                    <button type="button"
                                                                        (click)="displayPIX(payment.details.contents.banking_billet.pix.qrcode_image, payment.details.contents.banking_billet.pix.qrcode)"
                                                                        class="btn btn-secondary"><fa-icon
                                                                            [icon]="qrcode"
                                                                            aria-hidden="true"></fa-icon> Pague com
                                                                        PIX</button>
                                                                </div>
                                                                <div class="col-md-2 col-sm-12 mt-2"
                                                                    *ngIf="payment.details.bankSuccess">
                                                                    <a type="button"
                                                                        href="{{ payment.details.contents.banking_billet.pdf.charge }}"
                                                                        target="_blank" class="btn btn-primary"><fa-icon
                                                                            [icon]="download"
                                                                            aria-hidden="true"></fa-icon> Visualizar
                                                                        Boleto</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12 text-center unselectable">
                                                            <div *ngIf="product.subs.payments.open.length == 0">
                                                                <fa-icon [icon]="cheers" aria-hidden="true"
                                                                    size="5x"></fa-icon>
                                                                <br>
                                                                <br>
                                                                <p>Não existem pagamentos em aberto!</p>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="tab-pane fade" [id]='"paid_"+product.connectionId' role="tabpanel">
                                                <div style="max-height: 512px; overflow-y: scroll; overflow-x: scroll;">
                                                    <div class="card mt-3"
                                                        *ngFor="let payment of product.subs.payments.paid">
                                                        <div class="card-header">
                                                            <b class="text-black">Cobrança #{{payment.id}} - </b>
                                                            <b *ngIf="payment.status == 0" class="title-warn">Aguardando
                                                                Pagamento</b>
                                                            <b *ngIf="payment.status == 1" class="title-warn">Aguardando
                                                                Pagamento</b>
                                                            <b *ngIf="payment.status == 2" class="title-safe">Paga</b>
                                                            <b *ngIf="payment.status == 3"
                                                                class="title-danger">Inadimplente</b>
                                                            <b *ngIf="payment.status == 4"
                                                                class="title-danger">Cancelada</b>
                                                        </div>
                                                        <div class="card-body">
                                                            <div class="row">
                                                                <div class="col-md-6"><b>Valor: </b>{{
                                                                    payment.payment_amount | currency:'BRL':true:'1.2-2'
                                                                    }}</div>
                                                                <div class="col-md-6"><b>Data de Vencimento: </b> {{
                                                                    payment.expire_date | date:'dd/LL/yyyy' : 'GMT' }}
                                                                </div>

                                                            </div>
                                                            <hr>
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <b>Descrição: </b>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <p style="white-space:pre-wrap;">
                                                                        {{payment.payment_text}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-footer">
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                <br>
                <div class="card bg-light border-0 shadow">
                    <div class="card-header card-header-primary text-black">
                        <div class="pull-left unselectable">
                            <fa-icon [icon]="userCircle" aria-hidden="true" size="3x"></fa-icon>

                            <h4 class="card-title mt-3 unselectable">Informações Cadastrais</h4>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div class="form-group bmd-form-group">
                                    <label class="bmd-label-floating">CPF/CNPJ</label>
                                    <input type="text" class="form-control" id="client-cpfcnpj-field" name="CPF_CNPJ"
                                        mask="000.000.000-00||00.000.000/0000-00" [(ngModel)]="userData.cpfCnpj"
                                        required="" maxlength="18" disabled="">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group bmd-form-group">
                                    <label class="bmd-label-floating">Nome</label>
                                    <input type="text" class="form-control CLIENT_NAME" id="client-name-field"
                                        name="CLIENT_NAME" [(ngModel)]="userData.clientName" required="" disabled="">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                <div class="form-group bmd-form-group">
                                    <label class="bmd-label-floating">Email</label>
                                    <input type="email" class="form-control" id="client-mail-field"
                                        name="CLIENT_EMAIL_ADDRESS" [(ngModel)]="userData.clientMailAddress"
                                        required="">
                                </div>
                            </div>
                            <!---->
                            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <div class="form-group bmd-form-group">
                                    <label class="bmd-label-floating">Telefone 1</label>
                                    <input type="text" class="form-control" id="client-phone1-field"
                                        name="CLIENT_PHONE_1" [(ngModel)]="userData.clientPhone1" required="">
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                                <div class="form-group bmd-form-group">
                                    <label class="bmd-label-floating">Telefone 2</label>
                                    <input type="text" class="form-control" id="client-phone2-field"
                                        name="CLIENT_PHONE_2" [(ngModel)]="userData.clientPhone2" required="">
                                </div>
                            </div>
                        </div>
                        <br>
                        <div class="row">
                            <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                                <div class="form-group bmd-form-group">
                                    <label class="bmd-label-floating">Código Postal</label>
                                    <input type="text" class="form-control" id="client-zip-field" name="CLIENT_ZIP"
                                        mask="00000-000" [(ngModel)]="userData.clientZip" data-mask="00000-000"
                                        required="" autocomplete="off" maxlength="9" disabled="">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                                <div class="form-group bmd-form-group">
                                    <label class="bmd-label-floating">Cidade</label>
                                    <input type="text" class="form-control" id="client-city-field" name="CLIENT_CITY"
                                        [(ngModel)]="userData.clientCity" required="" disabled="">
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12 col-sm-12 col-xs-12">
                                <div class="form-group bmd-form-group">
                                    <label class="bmd-label-floating">Estado</label>
                                    <input type="text" class="form-control" id="client-state-field" name="CLIENT_STATE"
                                        [(ngModel)]="userData.clientState" required="" disabled="">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-9">
                                <div class="form-group bmd-form-group">
                                    <label class="bmd-label-floating">Endereço</label>
                                    <input type="text" class="form-control" id="client-addr-field" name="CLIENT_ADDRESS"
                                        [(ngModel)]="userData.clientAddress" required="" disabled="">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group bmd-form-group">
                                    <label class="bmd-label-floating">Complemento</label>
                                    <input type="text" class="form-control" id="client-complement-field"
                                        name="CLIENT_COMPLEMENT" [(ngModel)]="userData.clientComplement" required=""
                                        disabled="">
                                </div>
                            </div>
                        </div>
                        <br>

                    </div>
                    <div class="card-footer">
                        <button type="button" class="btn btn-primary" (click)="saveModificationsCallback()">Alterar
                            Cadastro</button>
                    </div>
                </div>
            </div>
            <!--div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">

                <div class="row mt-3">
                    <div class="col-md-12">
                        <div class="card bg-light border-0 shadow">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-1 text-right"><i class="fa fa-comment fa-3x" aria-hidden="true"></i></div>
                                    <div class="col-md-11 text-left"><h4 class="mt-3">Histórico de Chamadas</h4></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                        

                <div class="row p-3">
                    <div class="col-md-12">
                        <div class="card bg-light border-0 shadow-sm">
                            <div class="card-header">
                                <h5 class="card-title mt-3">BLSL FiberFast – Ajustes de Valores do Seu Plano Interação</h5>
                                <h6 class="card-title mt-3">Aberto via Web - 25/05/2020 18:04:08</h6>
                              </div>
                            <div class="card-body">
                                <a href="#ticket_details" data-toggle="collapse">Ver detalhes </a>
                                <div id="ticket_details" class="collapse"style="max-height: 30em; overflow-y: scroll;">
                                    
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-md-6"><b style="color:green">Fechado</b></div>
                                    <div class="col-md-6 text-right">Ultima Atualização: 25/05/2020 19:06:02</div>
                                </div>
                            </div>
                          </div>
                    </div>
                </div>
            </div-->

        </div>
    </div>
</section>