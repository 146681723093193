import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { spixConnector } from './spix-connector';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-spix-view',
  templateUrl: './spix-view.component.html',
  styleUrls: ['./spix-view.component.css']
})

export class SpixViewComponent implements OnInit {

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

  @Input() authenticatedGateway: string;
  @Input() connector: spixConnector;
  @Input() hasCloseControl: boolean;
  @Output() closeControl = new EventEmitter();

  randomTableId = ""

  toggleCloseControl(){
    this.closeControl.emit(true);
  }

  @ViewChild('modifierModal') modifierModal: any;
  @ViewChild('newPaymentModal') newPaymentModal: any;
  @ViewChild('renegotiateModal') renegotiateModal: any;
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
    
  selectedPaymentId = undefined
  selectedSubscription = undefined

  subscriptions = []
  payments = []
  details = {}
  detailsIsLoaded = false

  modifiers = {'active':[], 'expired':[], 'canceled':[]} 

  subscriptionsFullOn = true
  paymentDetails = false

  renegotiateDebtLoaded = false  
  renegotiateForm = undefined
  renegotiateSubsIdx = undefined

  closeResult = '';
  constructor(
    private http:HttpClient,
    private modalService: NgbModal
  ) {
  }

  open(content) {
    this.modalService.open(content, {size: 'lg', ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    this.connector.triggerReset.subscribe(value => {
      if(value){
        this.resetView()
      }
    });
  }

  ngOnInit(): void {
    this.randomTableId = this.makeid(5)
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthChange:false,
      dom: 'Blrtip',
      select:{style:'single'},
      buttons:['excel', 
      {
        text: 'Detalhes',
        action: () => {
          var selectedData = $("#"+this.randomTableId).dataTable().api().rows( { selected: true } ).data()
          if (selectedData[0] != undefined){
            this.triggerDetails()
            this.connector.bringPaymentDetails(selectedData[0][0]).then(e => {
              this.details = e['content'][0]
              this.details['payment']['payment_text'] = this.details['payment']['payment_text'].split('\n')
              this.detailsIsLoaded = true
            })
          }
          else{
            Swal.fire({
              title:"Opps.", 
              text:'Nenhum pagamento selecionado, selecione o pagamento na tabela!', timer: 2000,
              showConfirmButton: false,
              toast: true,
              position: 'top-right'
            })
          }
          
        }
      },
      {
        text: 'Novo Pagamento',
        action: () => {this.newPaymentClick()}
      }
    ],
    initComplete: function () {
      $('.dt-button').addClass( "btn btn-primary" )
    }
   }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.resetView()
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  
  paymentsSubscription(id): void{
    this.connector.bringPaymentsForSubscription(id).then(e => {
      if(e['status']){
        this.rerender()
        this.subscriptionsFullOn = false
      } 
    })
  }

  modifierGet(id:string):void{
    this.connector.modifierGet(id).then(e=>{
      if(e['status'])
        this.open(this.modifierModal)
    })
  }

  resetView(): void {
    this.detailsIsLoaded = false
    this.subscriptionsFullOn = true
    this.paymentDetails = false
  }

  triggerCancel(): void {

  }

  triggerDetails(): void {
    this.paymentDetails = true
    this.detailsIsLoaded = false
  }

  areYouSure(actionText, callback, success, onEnd){
    Swal.fire({
        title: 'Você tem certeza que deseja '+actionText+'?',
        text: "Essa ação não poderá ser revertida!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, eu tenho certeza!',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.isConfirmed)
          callback().then(e => {
            if(e.status)
              if(e['status']){
                Swal.fire({
                  title:"Sucesso", 
                  text:success, timer: 5000,
                  showConfirmButton: false,
                  toast: true,
                  icon:'success',
                  position: 'top-right'
                })
                onEnd()
              }
            else
              Swal.fire({
                title:"Opps", 
                text:'Algo deu errado, verifique seu console.', timer: 5000,
                showConfirmButton: false,
                toast: true,
                icon:'error',
                position: 'top-right'
              })
            }
        )
      })
  }

  subscriptionFullOnToggle():void{
    this.subscriptionsFullOn = !this.subscriptionsFullOn
  }
  paymentDetailsToggle():void{
    this.paymentDetails = !this.paymentDetails
    this.detailsIsLoaded = false
  }

  newPaymentClick():void{
    this.connector.clearNewPayment()
    this.open(this.newPaymentModal)
  }
  reloadPayments():void{
    this.paymentsSubscription(this.connector.selectedPayment)
    this.resetView()
    this.subscriptionFullOnToggle()
    this.modalService.dismissAll();
  }

  //Payment Calls

  cancelPaymentClick(paymentId:any):void{
    this.areYouSure('cancelar este pagamento', 
    ()=>{
      return this.connector.cancelPayment(paymentId)
    },
    'Pagamento foi cancelado com sucesso', 
    ()=>{
      this.reloadPayments()
    })
  }

  markAsPaidClick(paymentId:any):void{
    this.areYouSure('marcar como pago', 
    ()=>{
      return this.connector.markAsPaid(paymentId)
    },
    'Pagamento foi marcado como pago', 
    ()=>{
      this.reloadPayments()
    })
  }

  createPayment():void{
    this.areYouSure('criar esse pagamento', 
    ()=>{
      return this.connector.createPayment()
    },
    'Pagamento criado com sucesso', 
    ()=>{
      this.reloadPayments()
    })
  }

  emitPayment(id):void{
    this.areYouSure('emitir esse pagamento', 
    ()=>{
      return this.connector.emitPayment(id)
    },
    'Pagamento emitido com sucesso', 
    ()=>{
      this.reloadPayments()
    })
  }

  renegotiate(id):void{
    this.renegotiateDebtLoaded = false
    this.renegotiateSubsIdx = id 

    this.open(this.renegotiateModal)
    
    this.connector.renegotiateDebt(id).then(e => {
      if (e['status']){
        this.renegotiateDebtLoaded = true
        this.renegotiateForm = e['details']
      }
      
    })
  }
  createNegotiation(id):void{
    this.areYouSure('emitir essa nova cobrança', 
    ()=>{
      return this.connector.emitRenegotiation(this.renegotiateSubsIdx, this.renegotiateForm)
    }, 
    'Cobrança criada com sucesso', 
    ()=>{
	this.modalService.dismissAll()
	this.reloadPayments()
    })

  }
  
}
