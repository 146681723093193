<div class="row">
    <div class="col-md-4">
        <div class="card">
            <div class="card-header">Produtos Cadastrados</div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <a (click)='newItem()' class="btn btn-safe float-right">Adicionar Novo</a>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-12">
                        <input type="text"
                            [(ngModel)]="productCatalogApi.searchTerm"
                            (keyup)="searchChange()"
                            placeholder="Buscar" class="form-control">
                    </div>
                </div>
                <div style="max-height: 500px; overflow-x: hidden; overflow-y: scroll;">
                    <div class="row mt-2" *ngFor="let product of productCatalogApi.result">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                  <h5 class="card-title">{{product.name}}</h5>
                                  <div class="row">
                                      <div class="col-md-6">R$ {{product.suggestedPrice}}</div>
                                      <div class="col-md-6">{{product.rateLimit}} Mbps</div>
                                  </div>
                                  <p class="card-text"></p>
                                  <a class="btn btn-primary" (click)="editItem(product)">Editar</a>
                                </div>
                              </div>
                        </div>
                    </div>
                </div>
                
            </div>
          </div>
    </div>
    <div class="col-md-8">
        <div class="card">
            <div class="card-header">Formulário do Produto</div>
            <div class="card-body">
                <div class="row mt-2">
                    <div class="col-md-6">
                        <input type="text"
                            placeholder="Nome do Plano" [(ngModel)]="form.name"
                            [attr.disabled]="formLock? true:null"
                            class="form-control">
                    </div>
                    <div class="col-md-6">
                        <input type="number" step='10'
                            placeholder="Velocidade do Plano"  [(ngModel)]="form.rateLimit"
                            [attr.disabled]="formLock? true:null"
                            class="form-control">
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-6" >
                        <input type="text"
                            placeholder="Identificador do Scheme" [(ngModel)]="form.schemeIdentifier"
                            [attr.disabled]="formLock? true:null"
                            class="form-control">
                    </div>
                    <div class="col-md-6">
                        <input currencyMask 
                            [(ngModel)]="form.suggestedPrice"
                            placeholder="Valor sugerido" class="form-control"
                            [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align:'left' }"
                        />
                    </div>
                </div>
                <hr>
                    <div class="row">
                        <div class="col-md-12">
                            <a (click)="saveForm()" class="btn btn-safe float-right">Salvar</a>
                        </div>
                    </div>
            </div>
          </div>
    </div>
</div>