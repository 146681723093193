<div class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">Schemes</div>
            <div class="card-body">
                <table [attr.id]="randomTableId" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Clientes Online</th>
                        <th>Clientes Offline</th>
                        <th>Clientes Suspensos</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let scheme of loadedSchemes">
                        <td>{{scheme.fromScheme['schemeName']}}</td>
                        <td>{{scheme.onlineCount}}</td>
                        <td>{{scheme.offlineCount}}</td>
                        <td>{{scheme.defaultedCount}}</td>
                        <td>
                            <b *ngIf="scheme.isAlive"class="title-safe">Online</b>
                            <b *ngIf="!scheme.isAlive"class="title-danger">Offline</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>   
            </div>
          </div>
    </div>
</div>

<div class="row mt-2 mb-5" [hidden]="!schemeSelected" id="condoView">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header unselectable">{{selected.fromScheme['schemeName']}} - {{selected.latestUpdateDate | date:'dd/LL/yyyy hh:mm':'-0600' }}</div>
            <div class="card-body"> 


                <div class="row">
                    <div class="col-md-8">
                        <div class="row mt-2">
                            <div class="col-md-12">
                                <div class="card">
                                    <div class="card-body unselectable"> 
                                        <h5>Consumo de Banda</h5>
                                        <div class="row text-center">
                                            <img *ngIf="renderGifs" style="margin-left: auto; margin-right: auto; width: 65%;" id="bandwidth_usage_b64" [attr.src]="selected.latestBandwidthUsageGraph">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <div class="row mt-2">
                            <div class="col-md-6 pr-1">
                                <div class="card">
                                    <div class="card-body unselectable"> 
                                        <h5>Uso da CPU</h5>
                                        <div class="row text-center">
                                            <img *ngIf="renderGifs" style="margin-left: auto; margin-right: auto; width: 75%;" id="cpu_usage_b64" [attr.src]="selected.latestCpuUsageGraph">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 pl-1">
                                <div class="card">
                                    <div class="card-body unselectable"> 
                                        <h5>Uso de Memória</h5>
                                        <div class="row text-center">
                                            <img *ngIf="renderGifs" style="margin-left: auto; margin-right: auto; width: 75%;" id="memory_usage_b64" [attr.src]="selected.latestMemoryUsageGraph">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-body">
                                <h5 class="unselectable">Clientes</h5>
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="online-tab" data-toggle="tab" href="#online" role="tab"
                                            aria-controls="online" aria-selected="true">Online</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="offline-tab" data-toggle="tab" href="#offline" role="tab"
                                            aria-controls="offline" aria-selected="false">Offline</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="suspenso-tab" data-toggle="tab" href="#suspenso" role="tab"
                                            aria-controls="suspenso" aria-selected="false">Suspensos</a>
                                    </li>
                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade mt-2 show active" id="online" role="tabpanel" aria-labelledby="Online-tab">
                                        <app-scheme-detail-clientlist-table 
                                            #onlineClient
                                            [(content)]="selected['onlineList']"
                                        ></app-scheme-detail-clientlist-table>
                                    </div>
                                    <div class="tab-pane mt-2 fade" id="offline" role="tabpanel" aria-labelledby="offline-tab">
                                        <app-scheme-detail-clientlist-table
                                            #offlineClient
                                            [(content)]="selected['offlineList']"
                                        ></app-scheme-detail-clientlist-table>
                                    </div>
                                    <div class="tab-pane mt-2 fade" id="suspenso" role="tabpanel" aria-labelledby="suspenso-tab">
                                        <app-scheme-detail-clientlist-table #defaultedClient 
                                            [(content)]="selected['defaultedList']"
                                        ></app-scheme-detail-clientlist-table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
          </div>
    </div>
</div>

