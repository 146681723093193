

<div *ngIf="backendAutoCompleate !== undefined" class="row">
    <div class="col-md-12">
        <div class="card">
            <div class="card-header">
              Cadastro do Scheme
            </div>
            <div class="card-body">
              <p class="card-text">Para iniciar, digite o Nome do scheme.</p>
              <div class="row">
                <div class="mt-2 col-md-10">
                  <input type="text"
                   [(ngModel)]="schemeForm.schemeName"
                   placeholder="Nome do Scheme" class="form-control">
                </div>
                <div class="mt-2 col-md-2">
                    <button href="#" (click)="searchScheme()"  class="btn btn-safe w-100">Buscar</button>
                </div>
              </div>
              <div class="row">
                  <div class="mt-2 col-md-6">
                    <input [(ngModel)]="schemeForm.schemeContact.emailAddress"
                     [attr.disabled]="formLock? true:null" type="text" placeholder="Email" class="form-control">
                  </div>
                  <div class="mt-2 col-md-3">
                    <input [(ngModel)]="schemeForm.schemeContact.phone1"
                     [attr.disabled]="formLock? true:null" type="text" placeholder="Telefone 1" class="form-control">
                  </div>
                  <div class="mt-2 col-md-3">
                    <input [(ngModel)]="schemeForm.schemeContact.phone2" 
                    [attr.disabled]="formLock? true:null" type="text" placeholder="Telefone 2" class="form-control">
                  </div>
              </div>
              <div class="row">
                <div class="mt-2 col-md-2">
                  <input mask="00000-000" [(ngModel)]="schemeForm.schemeAddress.zip" (keyup)="zipKeyUp()"
                   [attr.disabled]="formLock? true:null" type="text" placeholder="CEP" class="form-control">
                </div>
                <div class="mt-2 col-md-2">
                  <input  [(ngModel)]="schemeForm.schemeAddress.city" 
                   [attr.disabled]="locationFormLock? true:null" type="text" placeholder="Cidade" class="form-control">
                </div>
                <div class="mt-2 col-md-2">
                  <input [(ngModel)]="schemeForm.schemeAddress.state" 
                   [attr.disabled]="locationFormLock? true:null" type="text" placeholder="Estado" class="form-control">
                </div>
                <div class="mt-2 col-md-6">
                  <input [(ngModel)]="schemeForm.schemeAddress.neighborhood" 
                   [attr.disabled]="locationFormLock? true:null" type="text" placeholder="Bairro" class="form-control">
                </div>
              </div>
              <div class="row">
                <div class="mt-2 col-md-10">
                  <input [(ngModel)]="schemeForm.schemeAddress.street" 
                   [attr.disabled]="locationFormLock? true:null" type="text" placeholder="Rua" class="form-control">
                </div>
                <div class="mt-2 col-md-2">
                  <input [(ngModel)]="schemeForm.schemeAddress.number" 
                   [attr.disabled]="locationFormLock? true:null" type="text" placeholder="Numero" class="form-control">
                </div>
              </div>
              <hr>
              <div class="row">
                <div class="mt-2 col-md-4">
                  <input [(ngModel)]="schemeForm.schemeCapacity" [attr.disabled]="formLock? true:null" 
                   type="number" step=50 placeholder="Capacidade Scheme" class="form-control">
                </div>
                <div class="mt-2 col-md-4">
                  <input [(ngModel)]="schemeForm.backend.schemeBackendName" [attr.disabled]="formLock? true:null" 
                   type="text" placeholder="Backend" class="form-control" (keyup)="backendOnKeyUp()" list="autoCompl" autocomplete="off">

                   <datalist id="autoCompl">
                        <option *ngFor="let option of backendAutoCompleate.result" [value]="option.schemeBackendName">
                            {{option._id}}
                        </option>
                    </datalist>
                </div>
                <div class="mt-2 col-md-4">
                  <input [(ngModel)]="schemeForm.schemeIp" [attr.disabled]="formLock? true:null" 
                   type="text" placeholder="IP do Scheme" class="form-control" autocomplete="off">
                </div>
              </div>
              <div class="row">
                <div class="mt-2 col-md-2">
                  <button href="#" (click)="generateSchemePassword()" [attr.disabled]="formLock? true:null" class="btn btn-safe w-100">Gerar Senha</button>
                </div>
                <div class="mt-2 col-md-10">
                  <input [(ngModel)]="schemeForm.schemePassword" [attr.disabled]="formLock? true:null" 
                   type="text" placeholder="Senha do Scheme" class="form-control" autocomplete="off">
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <button href="#" (click)="saveScheme()" [attr.disabled]="formLock? true:null" class="btn btn-safe float-right">Salvar</button>
                </div>
              </div>
            </div>
          </div>
    </div>

</div>



