
<div>
    <div class="loader" *ngIf="!loaded"></div>
</div>
<div [hidden]="!loaded">
    <table [attr.id]="randomTableId" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
        <thead>
        <tr>
            <th>ID</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of loadedContent">
            <td *ngFor="let item_ of item">{{item_}}</td>
        </tr>
        </tbody>
    </table>  
</div>
 
