import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import Swal from 'sweetalert2';
import { SchemeDetailClientlistTableComponent } from '../scheme-detail-clientlist-table/scheme-detail-clientlist-table.component';
import { ThrowStmt } from '@angular/compiler';
import { AuthenticatedGateway } from 'src/app/common/alex';

@Component({
  selector: 'app-scheme-detail-view',
  templateUrl: './scheme-detail-view.component.html',
  styleUrls: ['./scheme-detail-view.component.css']
})
export class SchemeDetailViewComponent implements OnInit {
  @Input()  authenticatedGateway: AuthenticatedGateway;

  @ViewChild("onlineClient") onlineClient: SchemeDetailClientlistTableComponent;
  @ViewChild("offlineClient") offlineClient: SchemeDetailClientlistTableComponent;
  @ViewChild("defaultedClient") defaultedClient: SchemeDetailClientlistTableComponent;
  
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject();
  randomTableId = ''
  schemeSelected = false
  renderGifs = false
  trueBool = true

  selected = {
    fromScheme:{schemeName:''},
    onlineList:[],
    offlineList:[],
    defaultedList:[],
    latestUpdateDate:""
  }

  areYouSure(actionText, describeText, callback, success, onEnd){
    Swal.fire({
        title: 'Você tem certeza que deseja '+actionText+'?',
        text: describeText,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, eu tenho certeza!',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.isConfirmed)
          callback().then(e => {
            if(e.status)
              if(e['status']){
                Swal.fire({
                  title:"Sucesso", 
                  text:success, timer: 5000,
                  showConfirmButton: false,
                  toast: true,
                  icon:'success',
                  position: 'top-right'
                })
                onEnd()
              }
            else
              Swal.fire({
                title:"Opps", 
                text:'Algo deu errado, verifique seu console.', timer: 5000,
                showConfirmButton: false,
                toast: true,
                icon:'error',
                position: 'top-right'
              })
            }
        )
      })
  }

  loadSelected(id){
    this.schemeSelected = false
    this.renderGifs = false

    for(let data of this.loadedSchemes){
        if(data['fromScheme']['schemeName'] == id){
          this.selected = Object.assign({},data)
          break
      } 
    }
    this.selected['onlineList'] = this.selected['onlineList'].map((x)=>{return [x]})
    this.selected['offlineList'] = this.selected['offlineList'].map((x)=>{return [x]})
    this.selected['defaultedList'] = this.selected['defaultedList'].map((x)=>{return [x]})
    this.selected['latestBandwidthUsageGraph'] = 'data:image/gif;base64,'+this.selected['latestBandwidthUsageGraph'] 
    this.selected['latestCpuUsageGraph'] = 'data:image/gif;base64,'+this.selected['latestCpuUsageGraph'] 
    this.selected['latestMemoryUsageGraph'] = 'data:image/gif;base64,'+this.selected['latestMemoryUsageGraph'] 
    this.selected['latestUpdateDate'] = this.selected['latestUpdateDate']
    
    this.onlineClient.startLoading()
    this.offlineClient.startLoading()
    this.defaultedClient.startLoading()

    this.schemeSelected = true
    setTimeout(()=>{ 
      this.onlineClient.rerender()
      this.offlineClient.rerender()
      this.defaultedClient.rerender()
      this.renderGifs = true
     }, 500);

    
  }
  httpOptions = {
      headers: new HttpHeaders({ 
        'Access-Control-Allow-Origin':'*',
      })
  };
  loadedSchemes = []
  constructor(
    private http:HttpClient,
    private el: ElementRef
  ) {
    
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }

  makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }

  ngOnInit(): void {
    this.randomTableId = this.makeid(5)

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      lengthChange:false,
      dom: 'Blfrtip',
      searching: true,
      select:{style:'single'},
      buttons:[ 
      {
        text: 'Detalhes',
        action: () => {
          var selectedData = $("#"+this.randomTableId).dataTable().api().rows( { selected: true } ).data()
          if (selectedData[0] != undefined){
            this.loadSelected(selectedData[0][0])
          }
          else{
            Swal.fire({
              title:"Opps.", 
              text:'Nenhum scheme selecionado, selecione um scheme na tabela!', timer: 2000,
              showConfirmButton: false,
              toast: true,
              position: 'top-right'
            })
          }
          
        }
      }
    ],
    initComplete: function () {
      $('.dt-button').addClass( "btn btn-primary" )
    }
   }
   this.authenticatedGateway.authGet('harpy/schemeStatus').then(
    (d)=>{
      this.loadedSchemes = d.payload['details']
      this.rerender()
    }
   ).catch(e=>{console.info(e)})
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

}
