import { HttpClient, HttpHeaders } from '@angular/common/http';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { AuthenticatedGateway } from 'src/app/common/alex';

export class ApiAutoCompleateModel{
    lastAutoCompleate = ' '
    searchTerm = ''
    harpyLoading = true
    result = []
    bufferedResult = []

    constructor(
        public authenticatedEndpoint: AuthenticatedGateway,
        public key:string,
        public model:string
    ) { 
      this.harpyLoading = true

      authenticatedEndpoint.authGet(
        'harpy/'+this.model
      ).then(
        (apiResponse)=>{
          let d = apiResponse.payload
          if(d.details.length > 0){
              this.bufferedResult = d.details
              this.result = [...this.bufferedResult]
          } 
          else {
              this.result = []
          }
          this.harpyLoading = false
          this.apiAutoCompleate()
        }
      ).catch(
        (e) => {
          console.info(e)
        }
      )
    }

  apiAutoCompleate():void{
    if(!this.harpyLoading){
      this.result = [...this.bufferedResult];
      this.result = this.result.filter(e=>{return e[this.key].includes(this.searchTerm)})
    }
    else{
        setTimeout(this.apiAutoCompleate, 250);
    }
  }

  findSelectedObj(obj){
    for(let r in this.bufferedResult){
      
      if(this.bufferedResult[r][this.key] == obj)
        return this.bufferedResult[r]
    }
    return undefined
  }
}